import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const DashboardStyles = makeStyles(() => ({
    flexBox: {
        display: "flex", justifyContent: "center", alignItems: "center"
    },
    btnBox: {
        width: "538px !important", padding: "8px 10px !important", borderRadius: "12px !important", backgroundColor: "#F4F8FF !important", display: "flex",
        [useTheme().breakpoints.down('lg')]: {
             width: "438px !important"
        }
    },
    activeButton: {
        font: "15px Quicksand !important", minWidth: "80px !important", borderRadius: "8px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "60px !important"
       }
    },
    inActiveButton: {
        font: "15px Quicksand !important", minWidth: "80px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "60px !important"
       }
    },
    pendingActiveButton: {
        font: "15px Quicksand !important", minWidth: "187px !important", borderRadius: "8px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "157px !important"
       }
    },
    pendingInActiveButton: {
        font: "15px Quicksand !important", minWidth: "187px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "157px !important"
       }
    },
    approvedActiveButton: {
        font: "15px Quicksand !important", minWidth: "96px !important", borderRadius: "8px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "76px !important"
       }
    },
    approvedInActiveButton: {
        font: "15px Quicksand !important", minWidth: "96px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "76px !important"
       }
    },
    rejectedActiveButton: {
        font: "15px Quicksand !important", minWidth: "119px !important", borderRadius: "8px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "99px !important"
       }
    },
    rejectedInActiveButton: {
        font: "15px Quicksand !important", minWidth: "119px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Quicksand !important',
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Quicksand !important',
            minWidth: "99px !important"
       }
    },
    borderButton: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "40px !important", height: "40px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important", marginRight: "-10px !important"
    },
    borderButton1: {
        all: "unset", display: "flex !important", justifyContent: "center !important", alignItems: "center !important", cursor: "pointer", textAlign: "center !important", fontSize: "15px !important", fontFamily: "Quicksand CY !important", width: "52px !important", height: "44px !important", border: "1.5px solid rgba(199, 204, 211, 1) !important", background: "#ffffff !important", borderRadius: "8px !important", marginRight: "-10px !important"
    },
    searchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: "400",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    profileTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Quicksand !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    menuItem: {
        font: "14px Quicksand !important",
        fontWeight: `${400} !important`,
        color: "#000000 !important",
        height: "35px !important"
    },
    countInfoBox: {
        minWidth: "21px !important", height: "14px !important", background: "#F70D0D", padding: "0px 0px", borderRadius: "36px", display: "flex !important", justifyContent: "center !important", alignItems: "center !important"
    },
    smallWhite: {
        font: "12px Quicksand !important"
    },
    newCustomAccordion: {
        padding: "6px 12px !important",
        backgroundColor: "#ffffff !important",
        margin: "15px 0px !important",
        borderRadius: "20px !important",
        boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05) !important",
        '&:before': { display: "none" },
        '&:hover': {
            borderRadius: '8px',
            transition: "all .3s ease",
            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
        }
    },
    AccordionSummary: {
        backgroundColor: "#ffffff",
        height: "70px"
    },
    linkText: {
        font: "12px Quicksand !important",
        fontWeight: "400 !important",
        color: "#0C75EB !important",
        cursor: "pointer !important",
    },
    circularIcon: {
        height: "14px !important",
        width: "14px !important"
    },
    divider: {
        margin: "0px !important",
        color: "#EAECF0 !important"
    },
    listItem: {
        padding: "0px !important",
        height: "53px !important",
        cursor: "pointer"
    },
    listItemActive: {
        padding: "0px !important",
        height: "53px !important",
        background: "#E4F1FF !important",
        cursor: "pointer"
    },
    startDateBox: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        width: '30px !important',
        height: '14px !important',
        borderRadius: '50px',
        opacity: '0px',
    },
    boxContainer: {
        height: "60vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start', [useTheme().breakpoints.up("xl")]: {
            height: "55vh",
        }
    },
    zohoIcon:{
        height: '24px', width: '24px', marginRight: '5px',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    zohoRefresh:{
        height: '24px', width: '24px', marginLeft: '7px',cursor:'pointer',
        [useTheme().breakpoints.down('xl')]: {
            height: '15px', width: '15px'
        },
        [useTheme().breakpoints.down('lg')]: {
            height: '10px', width: '10px'
        }
    },
    syncedSpan:{
        display:'flex',
        justifyContent:'center',
    },
    quickbooksbg: {
        background: '#DFFCE9 !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    arrowSize: {
        width: '20px !important',
        height: '20px !important',
        cursor: 'pointer !important'
    },
    zohobooksbg: {
        background: '#C8DBEC !important',
        padding: '4px 8px 4px 4px !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    },
    menuBg: {
        height: "35px",
        "&:hover": {
            background: 'none !important'
        },
        "&:focus": {
            background: 'none !important'
        }
    },
    greyAvatar: {
        filter: 'grayscale(100%)',
        opacity: 0.2,
        width: '24px !important',
        height: '24px !important'
    },
    activeProfile: {
        width: '24px !important',
        height: '24px !important'
    },
    EmployeesSearchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: "400",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    PDFRenderBox: {
        position: "relative",
        "@media (max-width:455px)": {
            width: "100% !important",
        },
        cursor: "pointer",
        height: "300px",
        width: "100%",
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.3s',
        background: 'rgba(0,0,0,0.5)',
        opacity: 1 ,
        borderRadius:"8px",
        '&:hover': { opacity: 0.4,transform: 'scale(1.05)', }
    },
    PDFBoxDownloadAndViewBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%', 
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        transition: 'opacity 0.3s',
        borderRadius:"8px",
        opacity:0, 
        pointerEvents: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        "$PDFRenderBox:hover &": { 
            opacity: 1,
            pointerEvents: 'auto',
        }
    },

    PDFRenderBox1: {
        position: "relative",
        "@media (max-width:455px)": {
            width: "100% !important",
        },
        cursor: "pointer",
        height: "300px",
        width: "100%",
        transition: '0.3s',
        background: 'rgba(0,0,0,0.5)',
        borderRadius:"8px",
    },
    PDFBoxDownloadAndViewBox1: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%', 
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        borderRadius:"8px", 

    }
}))

export default DashboardStyles;
