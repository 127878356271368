import { Autocomplete, Box, FormHelperText, Paper, TextField, inputLabelClasses } from '@mui/material';
import React from 'react';
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '../customButton/Button';
import ClearIcon from '@mui/icons-material/Clear';

function Select(props) {
    const { options, name, label, onChange, value, helperText, error, buttonName, buttonHandler, showButton, required, placeholder, ...extra } = props;
    /**
   * Props:
   * - name: target name for input, expected type string
   * - value: target value, expected type string
   * - onChange: onChange functionality, expected type Function
   * - label: label name for input field, expected type string
   * - options: Menu items for select field, expected type Array
   * - helperText: error message is placed below the input field
   */

    const CustomPaper = React.forwardRef((props, ref) => (
        <Paper
            onMouseDown={(e) => e.preventDefault()}
            sx={{
                maxHeight: 250,
                overflowY: 'auto', // Set overflow to 'auto' to enable the scrollbar
                scrollbarWidth: 'none', // Set the width of the scrollbar (thin, auto, etc.)
                msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
                boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                position: 'relative',
                "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for Chrome, Safari, and newer versions of Edge
                },
            }}><Box {...props} ref={ref}
                style={{
                    maxHeight: 180,
                    overflowY: 'auto', // Set overflow to 'auto' to enable the scrollbar
                    scrollbarWidth: 'none', // Set the width of the scrollbar (thin, auto, etc.)
                    msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
                    "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar for Chrome, Safari, and newer versions of Edge
                    },
                }} />
            {
                showButton &&
                <Box sx={{ margin: '8px' }}>
                    <Button dropdownAdd onClick={buttonHandler}>Add {buttonName}</Button>
                </Box>

            }
        </Paper>
        // </Paper>
    ));

    const valueReturn = (option) => {// eslint-disable-next-line
        let a = options.find((i) => {
            if (i.value === option.value) {
                return i
            }
            else if (i === option) {
                return i
            }
            else if (i.id === option) {
                return i
            }
            else if (i.value === option) {
                return i
            }
            else if (i.id === option.id) {
                return i
            }
        })
        if (a === undefined || option === '') {
            return ''
        }
        else {
            return a.value
        }
    }

    const getName = (e, name1) => {
        let event = {}
        event.target = {
            name: name,
            value: name1 === null ? '' : name1.id
        }
        return event
    }
    return (
        <div style={{ marginTop: "-8px", position: 'relative' }}>
            <Autocomplete
                {...extra}
                disablePortal
                popupIcon={<KeyboardArrowDownIcon />}
                PaperComponent={CustomPaper}
                name={name}
                options={options}
                autoHighlight
                value={value}
                clearIcon={value ? <ClearIcon style={{ fontSize: 22 }} /> : null}
                onChange={(e, val) => { onChange(getName(e, val)) }}
                getOptionLabel={(option) => valueReturn(option)}
                // getOptionLabel={(option) => (option ? option.id ? option.id : option.value ? option.value : option ? option : '' : '')}
                getOptionSelected={(option, value) => option.id === value}
                getOptionDisabled={(option) =>
                    option.disabled === true
                }
                isOptionEqualToValue={(option, value) => option.id === value}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, font: '14px Quicksand !important', fontWeight: '400 !important', padding: '10px !important', color: '#262626 !important' }} {...props} key={option.uniqueIdentifier}>
                        {option.value ? option.value : null}
                        {option.days ? option.days : null}
                        {option.name ? option.name : null}
                        {option.parameter ? option.parameter : null}
                        {option.date ? option.date : null}
                    </Box>
                )}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            label={label}
                            variant="filled"
                            required={required ? true : false}
                            margin="dense"
                            placeholder={placeholder}
                            InputLabelProps={{
                                sx: {
                                    "& .MuiFormLabel-asterisk": {
                                        color: "red !important"
                                    },
                                    color: (helperText && (value == null || value == "")) || error ? 'red' : '#737373',
                                    font: '14px Quicksand !important',
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        "& .MuiFormLabel-asterisk": {
                                            color: "red !important"
                                        },
                                        color: (helperText && (value == null || value == "")) || error ? 'red' : props.disabled ? '#737373' : '#0C75EB',
                                        font: '14px Quicksand !important',
                                    },
                                    fontWeight: `${400} !important`
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: { font: '14px Quicksand', fontWeight: 600, color: "#525252 !important" },
                                // startAdornment: showButton ? <Text onClick={buttonHandler}>Add</Text> : ''
                                // Conditionally render the clear icon based on the value
                                // endAdornment: value ? params.InputProps.endAdornment : null,
                                // endAdornment: value || params.inputProps.value ? params.InputProps.endAdornment : null,
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiFilledInput-root': {
                                    borderRadius: '8px',
                                    background: props.disabled ? "#FAFAFA !important" : '#ffffff',
                                    border: props.disabled ? 'none' : (helperText && (value == null || value == "")) || error ? '1px solid red !important' : '1px solid #C7CCD3',
                                    '&.Mui-focused': {
                                        background: '#ffffff',
                                        border: props.disabled ? 'none' : (helperText && (value == null || value == "")) || error ? '1px solid red !important' : "1px solid #0C75EB",
                                    },
                                    '.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input.Mui-disabled': {
                                        '-webkit-text-fill-color': '#525252',
                                        background: props.disabled && "#FAFAFA !important",
                                    },
                                    '&:hover': {
                                        background: props.disabled ? "#FAFAFA" : '#ffffff',
                                    },
                                },
                            }}
                        />
                    </>
                )}
                sx={{
                    marginTop: '-8px'
                }}
            />
            {helperText && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
        </div>
    )
}

Select.propTypes = {
    onChange: PropTypes.func,  // onChange function prop
    name: PropTypes.string,  // Name prop
    value: PropTypes.any,  // Value prop
    label: PropTypes.string,  // Label prop
    options: PropTypes.array,  // Options prop
    helperText: PropTypes.bool,  // Helper text prop
    error: PropTypes.bool, // Error text prop
    buttonName: PropTypes.bool, // Add Button Name
    buttonHandler: PropTypes.func, // Add Button function
    showButton: PropTypes.bool
};

export default Select;
