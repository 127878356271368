export const forgotPasswordDefaultConfig = {
    emailId:{
         "placeholder": "",
         "label": "Email Address",
         "required": true,
         "isDisabled":false,
         "isVisible":true,
         "requiredMsg": "Email is required",
         "pattern":"/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
         "patternMsg": "Please enter a valid Email Id"
      }
    }



export const signUpDefaultConfig = {

    "first_name": {
        "label": "First Name",
        "placeholder": "",
        "required": true,
        "requiredMsg": "This field is required",
        "maxLength": 40,
        "pattern": "/^[A-Za-z\\s'-]+$/",
        "patternMsg": "Please enter a valid First name",
        "isVisible": true,
    },
    "middle_name": {
        "label": "Middle Name",
        "placeholder": "",
        "required": false,
        "requiredMsg": "This field is required",
        "maxLength": 40,
        "pattern": "/^[A-Za-z\\s'-]+$/",
        "patternMsg": "Please enter a valid Middle Name",
        "isVisible": true,
    },
    "last_name": {
        "label": "Last Name",
        "placeholder": "",
        "required": true,
        "requiredMsg": "This field is required",
        "maxLength": 40,
        "pattern": "/^[A-Za-z\\s'-]+$/",
        "patternMsg": "Please enter a valid Last Name",
        "isVisible": true,
    },
    "gender": {
        "placeholder": "",
        "label": "Gender",
        "required": true,
        "requiredMsg": "This field is required",
        "pattern": "",
        "patternMsg": "",
        "isVisible": true,
    },
    "gender_description": {
        "label": "Gender Description",
        "placeholder": "",
        "required": true,
        "requiredMsg": "This field is required",
        "minLength": 3,
        "minLengthMsg": "Invalid Charaters",
        "maxLength": 50,
        "pattern": "/^[a-zA-Z\\s-]*$/",
        "patternMsg": "Invalid Charaters",
        "isVisible": true,
    },
    "organization_name": {
        "label": "Organization Name",
        "placeholder": "",
        "required": true,
        "requiredMsg": "This field is required",
        "maxLength": 60,
        "pattern": "/^[A-Za-z0-9 &@£$€¥-]{2,60}$/",
        "patternMsg": "Please enter a valid Organization name",
        "isVisible": true,
    },
    "email_id": {
        "placeholder": "",
        "label": "Email ID",
        "required": true,
        "requiredMsg": "This field is required",
        "pattern": "/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
        "patternMsg": "Please enter a valid Email Id",
        "isVisible": true,
    },
    "mobile_number": {
        "placeholder": "",
        "label": "Mobile Number",
        "required": true,
        "requiredMsg": "This field is required",
        "pattern": "/^[2-9][0-9]{2}-?[0-9]{3}-?[0-9]{4}$/",
        "patternMsg": "Please enter a valid Mobile Number",
        "isVisible": true,
    },
    "enable_login": {
        "required": true,
        "requiredMsg": "Please read and accept the terms of service and privacy policy",
        "isVisible": true,
        "label1": "I agree to the",
        "label2": "and",
        "linkText1": "terms of services",
        "linkText2": "privacy policy",
        "link1": "https://www.japfu.ai/terms-of-use",
        "link2": "https://www.japfu.ai/privacy-policy"
    },

}


export const createPasswordDefaultConfig = {
    password: {
        "placeholder": "",
        "label": "Enter Password",
        "required": true,
        "requiredMsg": "This field is required",
        "lowerCaseReg": "/.*[a-z].*$/",
        "lowerCaseTxt": "one",
        "upperCaseReg": "/.*[A-Z].*$/",
        "upperCaseTxt": "one",
        "digitReg": "/.*\\d.*$/",
        "digitTxt": "one",
        "specialCharacterReg": "/.*\\W.*$/",
        "specialCharacterTxt": "one",
        "passwordLengthTxt": "8",
        "pattern": "/^(?=.*\\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
        "patternMsg": "Password not matched to the expected scenario",
        "maxLength": null

    },
    confirm_password: {
        "placeholder": "",
        "label": "Confirm Password",
        "maxLength": null

    }
}

 export const resetPasswordDefaultConfig = {
        password:{
             "placeholder": "",
             "label": "Enter New Password",
             "required": true,
             "isDisabled":false,
             "isVisible":true,
             "requiredMsg":"This field is required",
             "lowerCaseReg": "/.*[a-z].*$/",
             "lowerCaseTxt":"one",
             "upperCaseReg":"/.*[A-Z].*$/",
             "upperCaseTxt":"one",
             "digitReg": "/.*\\d.*$/",
             "digitTxt":"one",
             "specialCharacterReg": "/.*\\W.*$/",
             "specialCharacterTxt":"one",
             "passwordLengthTxt":"8",
             "pattern": "/^(?=.*\\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
             "patternMsg":"Password not matched to the expected scenario",
             "maxLength":null
             
          },
        confirmpassword:{
              "placeholder": "",
              "label": "Confirm Password",
              "maxLength":null,
              "isDisabled":false,
              "isVisible":true,
              "required": true,
              "requiredMsg": "Password not matched",
              "pattern": "/^(?=.*\\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
              "patternMsg": "Password not matched to the expected scenario"
              
        }
    }

export const loginPageDefaultConfig = {
   email:{
         "placeholder": "",
         "label": "Email Address",
         "required": true,
         "requiredMsg": "Email is required",
         "pattern":"/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,249}\\.[a-zA-Z]{2,6}$/",
         "patternMsg": "Please enter a valid Email Id"
   },
   password:{
      "placeholder": "",
      "label": "Enter Password",
      "required": true,
      "isDisabled": false,
      "isVisible": true,
      "requiredMsg":"This field is required",
      "pattern": "/^(?=.*\\d)(?=.*[!@#$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
      "patternMsg":"Invalid Password"
      
   },
   otp:{
      "placeholder":"------",
      "otpFields":"6",
      "attempts":"3",
      "isDisabled": false,
      "isVisible": true

   }
}
