import { Autocomplete, Backdrop, Box, Card, CardContent, CardHeader, Chip, Grid, Slide, Step, Stepper, TextField, Stack, Checkbox, FormControl, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrownMnColorlibStepLabel, BrownMnCustomisedConnector, clientandEndclientStepper } from "../../../../theme";
import ClientAndEndClientStyles from "./ClientAndEndClientStyles";
import SwipeableViews from "react-swipeable-views";
import PropTypes from 'prop-types';
import { validate_emptyField, isValid, empty_zipcode, empty_city, empty_integer, validate_toHours, float_validation, validates_handlechange_float, validate_city, validate_zipcode, empty_Email_id, onNumberOnlyChange, onFloatOnlyChange, isValidMulti, address_validation, validate_empty_address, onNumberFloatCurrency, onNumberWithTwoDecimalOnlyChange, validates_float, empty_fields, onNumberWithTwoDecimalOnlyChangeCurrency } from '../../../../components/Validation';
import { useTheme } from '@mui/material/styles';
import Input from "../../../../components/input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from '../../../../assets/svg/Information.svg';
import LocalStorage from "../../../../utils/LocalStorage";
import moment from 'moment';
import Text from "../../../../components/customText/Text";
import Button from "../../../../components/customButton/Button";
import { BlackToolTip, NoPermission, addErrorMsg, addLoader, addWarningMsg, capitalizeWords, checkZipcode, dateFormat, removeLoader, scrollToTop } from "../../../../utils/utils";
import Date from "../../../../components/datePicker/Date";
import CommonApi from '../../../../apis/CommonApi';
import ClientsApi from "../../../../apis/admin/clients/ClientsApi";
import AddJobTitle from "../../addSelectForms/AddJobTitle";
import Search from '../../../../components/select/Select';
import Dropzone from "react-dropzone";
import download from '../../../../assets/svg/clients/download-Arrow.svg';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import ReusablePopup from "../../../../components/reuablePopup/ReusablePopup";
import AddRecruiter from "../../addSelectForms/AddRecruiter";
import cancelImg from '../../../../assets/svg/OffBoardPop.svg';
import Select from "../../../../components/select/Select";
import RemoveIcon from '@mui/icons-material/Remove';
import CustomRadioGroup from "../../../settings/configurations/configComponents/radio/CustomRadio";
import approvalDelete from '../../../../assets/svg/approvalDelete.svg';
import info from '../../../../assets/svg/fi_info.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/closeIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import placementSuccess from '../../../../assets/svg/placement/placement-success.svg';
import PlacementApi from "../../../../apis/admin/placements/PlacementApi";
import { ReactComponent as RemoveSvg } from '../../../../assets/svg/removeIcon.svg';
import { inputLabelClasses } from "@mui/material/InputLabel";
import blueinfo from '../../../../assets/svg/placement/blueinfo.svg';
import LoadingButton from "../../../../components/customButton/LoadingButton";
import defaultTs from '../../../../assets/svg/placement/defaultTS-Icon.svg';
import EmployeesApi from "../../../../apis/admin/employees/EmployeesApi";
import { handleChangeAddress, placement_address, placement_worklocation_address, resetAddressErrors, updateAddress, useZipcodeAPICall } from "../PlacementCommon";
import Worklocation from "../Worklocation";
import { ErrorMessages } from "../../../../utils/ErrorMessages";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// For changing the forms 
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ClientAndEndClient = () => {
    const currency = LocalStorage.getUserData().currency_symbol ? LocalStorage.getUserData().currency_symbol : "$";
    const options = [
        { value: 1, label: <Text largeGrey16>Same as Payrate</Text> },
        { value: 2, label: <Text largeGrey16>Fixed Value</Text> },
        { value: 3, label: <Text largeGrey16>Variable</Text> },
    ];

    const [activeStep, setActiveStep] = useState(0);
    const classes = ClientAndEndClientStyles()
    const theme = useTheme();
    const [error, setError] = useState({});
    const [value, setValue] = useState(0);
    const [deleteLevelOpen, setDeleteLevelOpen] = useState(false);
    const [deleteLevelIndex, setDeleteLevelIndex] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const employeeData = location && location.state && location.state.empData
    const [approvalDropdown, setApprovalDropdown] = useState([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [duplicateId, setDuplicateId] = useState('');
    const [submitLoader, setSubmitLoader] = useState(false);
    const [openDefaultTS, setOpenDefaultTS] = useState(false);
    const billType = [
        {
            id: 1,
            value: 'Hourly'
        }
    ]

    const clickHandler = () => {
        navigate('/configuration', { state: { from: 'placement' } });
    }

    const timesheetCycleDropdown = [
        {
            id: 'Weekly',
            value: 'Weekly'
        },
        {
            id: 'Bi Weekly',
            value: 'Bi Weekly'
        },
        {
            id: 'Semi Monthly',
            value: 'Semi Monthly'
        },
        {
            id: 'Monthly',
            value: 'Monthly'
        }
    ]
    const dayslist = require('../../../../utils/jsons/Days.json');
    const payTypes = require('../../../../utils/jsons/PayType.json');
    const locationType = require('../../../../utils/jsons/WorkLocationType.json');
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [erro, setErro] = useState([]);
    const [clientContList, setClientContList] = useState([]);
    const [endClientContList, setEndClientContList] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [recruitersList, setRecruitersList] = useState([]);
    const [docError, setDocError] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [endClientList, setEndclientList] = useState([]);// eslint-disable-next-line
    const [employeesList, setEmployeesList] = useState([]);// eslint-disable-next-line
    const [openJobTitle, setOpenJobtitle] = useState(false);
    const [openrecruiter, setOpenRecruiter] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [deletedchips, setDeletedchips] = useState([]);
    const [deletedLevels, setDeletedLevels] = useState([]);
    const [defaultHours, setDefaultHours] = useState('');// eslint-disable-next-line
    const [dataExists, setDataExists] = useState(false);
    const [lastWorkingDay, setLastWorkingDay] = useState('')
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    // Client Details useState
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        client_details: {
            client_id: "",
            client_reference_id: "",
            client_name: "",
            client_contact_id: "",
            end_client_id: "",
            endclient_reference_id: "",
            end_client_contact_id: "",
            lca_amount: ""
        },
        billing_details: {
            bill_type: 1,
            bill_rate: "",
            ot_bill_rate: "",
            effective_from: ""
        },
        placement_details: {
            job_title_id: '',
            work_email_id: '',
            recruiter_id: '',
            address_line_one: '',
            address_line_two: '',
            city: '',
            state_id: '',
            country_id: 2,
            zip_code: '',
            work_location_type: '',
            supporting_documents: [],
            address: placement_worklocation_address,
        },

        pay_rate_details: {
            pay_type: '',
            annual_pay: '',
            payroll_pay: '',
            pay_config_type: 1,
            ot_pay_config_type: '',
            ot_pay_config_value: '',
            ot_pay_config_multi: '',
            pay_details: [
                {
                    from_hour: '',
                    to_hour: '',
                    value: ''
                }
            ]
        },
        timesheet_details: {
            timesheet_cycle: '',
            default_hours: '08:00',
            is_timesheet_attachment_mandatory: false,
            timesheet_start_date: '',
            timesheet_start_day: '',
            approval_levels: [
                {
                    level: 1,
                    approval_users: []
                }
            ]
        },
    })

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) {
            jobTitleDropdown();
            countriesList()
            clientDropdownList()
            endClientDropdownList();
            getEmployees();
            getApprovalDropdown();
            recruiterDropdown();
            defaultHoursApi();
            setTimeout(() => {
                setState({
                    ...state,
                    placement_details: {
                        ...state.placement_details,
                        address: {
                            remote: { ...placement_address },
                            office: { ...placement_address }
                        }
                    }
                });
            }, 500);
        } // eslint-disable-next-line
    }, [])

    const handleTabChange = (param) => {
        state.pay_rate_details.pay_config_type = param;
        for (var i in state.pay_rate_details.pay_details) {
            state.pay_rate_details.pay_details[i].value = "";
        }
        // if (param == 2) {
        //     // state.pay_rate_details.ot_pay_config_type = "";
        //     // state.pay_rate_details.ot_pay_config_value = "";
        //     // state.pay_rate_details.ot_pay_config_multi = "";
        //     state.pay_rate_details.pay_details = [{ from_hour: "0", to_hour: "", value: "" }];
        //     setState({ ...state });
        //     setDocError([]);
        // } else if (param == 1) {
        //     state.pay_rate_details.annual_pay = "";
        //     state.pay_rate_details.pay_details = [{ from_hour: "0", to_hour: "", value: "" }];
        //     setState({ ...state });
        //     setDocError([]);
        // }
        setState({ ...state });
    }

    const handleChangeLevels = (e, newArr, index) => {
        if (!["", null, undefined].includes(e.target.value)) {
            let { id, value } = newArr[newArr.length - 1];
            let approvals = state.timesheet_details.approval_levels
            let approverIdsArr = approvals[index].approval_users;
            if (approverIdsArr.filter((i) => i.employee_id == id).length == 0) {
                approverIdsArr.push({ id: "", employee_id: id, value: value, full_name: value });
                setState({ ...state })
            }
        }
        setDataExists(true);
    }

    const handleDeleteChipLevels = (key, index) => {
        setDataExists(true);
        let approvals = state.timesheet_details.approval_levels;
        let newArr = approvals[index].approval_users;
        let deletedChipsArr = deletedchips;
        if (newArr[key].id !== '') {
            deletedChipsArr.push(newArr[index]);
        }
        newArr.splice(key, 1);
        setState({ ...state })
        setDeletedchips([...deletedChipsArr]);
        setDataExists(true);
    }


    const getApprovalDropdown = () => {
        CommonApi.getTimesheetApprovers().then((response) => {
            if (response.data.statusCode == 1003) {
                setApprovalDropdown(response.data.data);
            }
        });
    };

    const handleClick = () => {
        if (state.pay_rate_details.pay_details.length > 1 && state.pay_rate_details.pay_details[state.pay_rate_details.pay_details.length - 1].from_hour == "") {
            let errors = {};
            let err = [];// eslint-disable-next-line
            state.pay_rate_details.pay_details.map((item, index) => {
                errors = {};
                errors.to_hour = activeStep == 2 ? state.pay_rate_details.pay_details.length !== (index + 1) ? validate_toHours(item.to_hour, item.from_hour) : '' : '';
                errors.value = activeStep == 2 ? validates_float(item.value) : '';
                err.push(errors)
            })
            setDocError([...err]);
            addErrorMsg(`You can't perform this action before entering the above To Hours.`);
            return true;
        }
        var newObj = {
            from_hour: "",
            to_hour: "",
            value: ""
        }
        state.pay_rate_details.pay_details.push(newObj);
        setState({ ...state });
    }

    const handleDeleteLevelOpen = (index) => {
        setDeleteLevelOpen(true);
        setDeleteLevelIndex(index);
        setDataExists(true);
    }

    const getEmployees = () => {
        CommonApi.employees(LocalStorage.uid(), LocalStorage.getAccessToken(), 1).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmployeesList(res.data.data);
            }
        })
    }

    const handleRadio1Change = (event) => {
        state.pay_rate_details.ot_pay_config_type = event.target.value;
        if (event.target.value == 3) {
            state.pay_rate_details.ot_pay_config_value = "";
        } else {
            state.pay_rate_details.ot_pay_config_multi = "";
        }
        error['ot_pay_config_multi'] = "";
        error['ot_pay_config_value'] = "";
        error['ot_pay_config_type'] = "";
        setError({ ...error })
        setState({ ...state })
    };

    const handleRemove = (index) => {
        state.pay_rate_details.pay_details.splice(index, 1);
        // docError.splice(index, 1);
        // setDocError([...docError]);    
        if (state.pay_rate_details.pay_details.length == index) {
            state.pay_rate_details.pay_details[index - 1].to_hour = '';
        }
        if (state.pay_rate_details.pay_details.length > 2 && index != 0) {
            if (state.pay_rate_details.pay_details[index] != undefined) {
                state.pay_rate_details.pay_details[index].from_hour = Number(state.pay_rate_details.pay_details[index - 1].to_hour) + 1
            } else {
                state.pay_rate_details.pay_details[index - 1].from_hour = Number(state.pay_rate_details.pay_details[index - 2].to_hour) + 1
            }
        }
        if (state.pay_rate_details.pay_details.length == 2) {
            state.pay_rate_details.pay_details[1].from_hour = state.pay_rate_details.pay_details[0].to_hour !== "" ? Number(state.pay_rate_details.pay_details[0].to_hour) + 1 : ""
            state.pay_rate_details.pay_details[1].to_hour = "";
        }
        if (state.pay_rate_details.pay_details.length == 1) {
            state.pay_rate_details.pay_details[0].from_hour = 0;
            state.pay_rate_details.pay_details[0].to_hour = '';
        }
        state.pay_rate_details.pay_details[state.pay_rate_details.pay_details.length - 1].to_hour = "";
        let errors = {};
        let err = [];// eslint-disable-next-line
        state.pay_rate_details.pay_details.map((item, index) => {
            errors = {};
            errors.to_hour = activeStep == 2 ? state.pay_rate_details.pay_details.length !== (index + 1) ? validate_toHours(item.to_hour, item.from_hour) : '' : '';
            errors.value = activeStep == 2 ? validates_float(item.value) : '';
            err.push(errors)
        })
        setDocError([...err]);
        // if (state.pay_rate_details.pay_details.length == 1) {
        //     docError[index].to_hour = ''
        //     setDocError([...docError]);
        // }

        setState({ ...state });
        setDeleteLevelOpen(false);
        setDeleteLevelIndex(null);
    }

    const placementExists = (args) => {
        addLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            employee_id: employeeData.id,
            client_id: args
        }
        ClientsApi.duplicateCheck(data).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                setDuplicateId('')
            } else {
                setDuplicateId(args)
                addErrorMsg(res.data.message);
            }
        })
    }

    const defaultTsHandler = () => {
        addLoader('ss');
        const updateData = {
            request_id: LocalStorage.uid(),
            approval_levels: state.timesheet_details.approval_levels
        }
        PlacementApi.defaultTsUpdate(updateData).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader()
                state['employee_id'] = employeeData.id
                if (state.timesheet_details.default_hours.includes(':')) { }
                else {
                    state.timesheet_details.default_hours = `${state.timesheet_details.default_hours}:00`
                }
                state.billing_details['ot_bill_rate'] = state.billing_details.ot_bill_rate != '' ? state.billing_details.ot_bill_rate : state.billing_details.bill_rate
                placementStoreApi()
            } else {
                removeLoader()
                addErrorMsg(res.data.message);
            }
        })
    }

    const deleteChip = (index) => {
        state.placement_details.supporting_documents.splice(index, 1)
        setState({ ...state });
    }

    const handleDeleteLevel = (index) => {
        setDataExists(true)
        let arr = state.timesheet_details.approval_levels;
        let deletedLevelArr = deletedLevels;
        if (arr[index].id !== '') {
            deletedLevelArr.push(arr[index]);
        }
        arr.splice(index, 1);
        arr.forEach((ele, key) => {
            ele.rank = key + 1;
            ele.level = key + 1;
        });
        setState({ ...state });
        setDeletedLevels([...deletedLevelArr]);
    }

    const clientDropdownList = (args) => {
        ClientsApi.dropdown('client', 'Active').then((response) => {
            if (response.data.statusCode == 1003) {
                const capitalizedData = response.data.data.map((item) => ({
                    ...item,  // Spread the existing item properties
                    value: capitalizeWords(item.value) // Capitalize the 'value'
                }));
                setClientList(capitalizedData);
                if (args == undefined) { }
                else if (args) {
                    let x = response.data.data.find(i => i.id === args)
                    state.client_details['client_reference_id'] = x.reference_id && x.reference_id
                    state.client_details['client_id'] = args
                }
                else {
                    let y = response.data.data.find(i => i.id === state.client_details.client_id)
                    state.client_details['client_reference_id'] = y.reference_id && y.reference_id
                    state.client_details['client_id'] = args
                }
                setState({ ...state });
            }
        });
    };

    const endClientDropdownList = (args) => {
        ClientsApi.dropdown('end-client', 'Active').then((response) => {
            if (response.data.statusCode == 1003) {
                const capitalizedData = response.data.data.map((item) => ({
                    ...item,  // Spread the existing item properties
                    value: capitalizeWords(item.value)  // Capitalize the 'value'
                }));
                setEndclientList(capitalizedData);
                if (args == undefined) { }
                else if (args) {
                    let x = response.data.data.find(i => i.id === args)
                    state.client_details['endclient_reference_id'] = x.reference_id && x.reference_id
                    state.client_details['end_client_id'] = args
                }
                else {
                    let y = response.data.data.find(i => i.id === state.client_details.end_client_id)
                    state.client_details['endclient_reference_id'] = y.reference_id && y.reference_id
                    state.client_details['end_client_id'] = args
                }
                setState({ ...state });
            }
        });
    };

    const countriesList = () => {
        CommonApi.getCountryList('United States').then((res) => {
            if (res.data.statusCode === 1003) {
                state.placement_details.country_id = res.data.data[0].id
                setState({ ...state });
                statesList(res.data.data[0].id);
                setCountries(res.data.data);
            }
        })
    }

    const statesList = (id) => {
        CommonApi.getStatesList(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setStates(res.data.data);
            }
        })
    }

    const clientContactsList = (args) => {
        ClientsApi.contactsDropdown('client', args).then((response) => {
            if (response.data.statusCode === 1003) {
                setClientContList(response.data.data);
            }
        });
    };

    const endClientContactList = (args) => {
        ClientsApi.contactsDropdown('end-client', args).then((response) => {
            if (response.data.statusCode == 1003) {
                setEndClientContList(response.data.data);
            }
        });
    }

    const jobTitleDropdown = () => {
        CommonApi.getJobTitlesDropdownList('').then((response) => {
            if (response.data.statusCode == 1003) {
                const capitalizedData = response.data.data.map((item) => ({
                    ...item,  // Spread the existing item properties
                    value: capitalizeWords(item.value)// If there's no value, keep it as is
                }));
                setJobTitles(capitalizedData);
            }
        });
    };

    const recruiterDropdown = () => {
        CommonApi.getRecruiterDropdownList('').then((response) => {
            if (response.data.statusCode == 1003) {
                const capitalizedData = response.data.data.map((item) => ({
                    ...item,  // Spread the existing item properties
                    value: capitalizeWords(item.value) // If there's no value, keep it as is
                }));
                setRecruitersList(capitalizedData);
            }
        });
    };


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    // eslint-disable-next-line
    const newColoumnAdd = (action, index) => {
        let newArray = state.documents;
        let obj = {
            document_name: '',
            document_type_id: '',
            description: '',
            new_document_id: '',
            docName: ''
        }
        if (action == "add") {
            newArray.push(obj);
        }
        if (action == "remove") {
            newArray.splice(index, 1);
        }
        setState({ ...state, documents: newArray });
    }

    const handleAddNewLevel = () => {
        let arr = state.timesheet_details.approval_levels;
        arr.push({
            id: "",
            level: arr.length + 1,
            approval_users: [],
        },);
        setState({ ...state });
    }

    const dateChange = (e, name) => {
        // if (e == null) {
        //     var date = ''
        // }else{
        //     var date = e.$d
        // }
        var date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (name == 'effective_from') {
            state.billing_details[name] = date == '' ? '' : moment(date).format(dateFormat());
            setState({ ...state });
            handleValidate(event);
            if (date !== "" && moment(date, dateFormat()).isBefore(moment(employeeData?.date_of_joining, dateFormat()))) {
                error.effective_from = "Placement start date should not go beyond Employee Joining date";
                setError({ ...error })
            }
            if (date !== "" && lastWorkingDay !== '' && lastWorkingDay !== 'NaN' && moment(date, dateFormat()).isAfter(moment(lastWorkingDay, dateFormat()))) {
                error.effective_from = "Placement start date should not be greater than Employee Last working date";
                setError({ ...error })
            }
        } else {
            state.timesheet_details[name] = date == '' ? '' : moment(date).format(dateFormat())
            setState({ ...state })
            handleValidate(event)
        }
    }

    // HandleChange functions
    const changeHandler = (e, args, index) => {
        if (args == 'clients') {
            if (e.target.name === "lca_amount") {
                let value1 = e.target.value.trim();
                if (value1 == "$" || value1 == "") {
                    state.client_details[e.target.name] = "";
                    setState({ ...state }, handleValidate(e));
                    return;
                }
                value1 = value1.replace(currency, "");
                const regex = /^(?:\d{1,10}|\d{1,10}\.\d{0,2})$/;
                const numericValue = parseFloat(value1);
                if (regex.test(value1) && (!isNaN(numericValue) && numericValue <= 9999999999.99)) {
                    state.client_details[e.target.name] = value1;
                } else {
                    return false;
                }
                setState({ ...state }, handleValidate(e));
            }
            else if (e.target.name == 'client_id') {
                state.client_details[e.target.name] = e.target.value
                setState({ ...state });
                if (e.target.value !== '') {
                    clientContactsList(e.target.value);
                    clientDropdownList(e.target.value);
                    placementExists(e.target.value);
                    state.client_details.client_contact_id = ''
                } else {
                    state.client_details.client_reference_id = ''
                    state.client_details.client_contact_id = ''
                }
                const arr = clientList.filter(i => i.id == e.target.value)
                state.client_details['client_name'] = arr.length > 0 ? arr[0].value : ''
                state.client_details[e.target.name] = e.target.value
                setState({ ...state });
            } else if (e.target.name == 'end_client_id') {
                state.client_details[e.target.name] = e.target.value
                if (e.target.value !== '') {
                    endClientContactList(e.target.value);
                    endClientDropdownList(e.target.value);
                    state.client_details.end_client_contact_id = ''
                } else {
                    state.client_details.endclient_reference_id = ''
                    state.client_details.end_client_contact_id = ''
                }
                setState({ ...state });
            } else {
                state.client_details[e.target.name] = e.target.value
                setState({ ...state });
            }
        } else if (args == 'billing') {
            if (e.target.name === "bill_rate" || e.target.name === "ot_bill_rate") {
                var value = e.target.value.trim();
                value = value.replace(currency, "");
                if (value.length > 4) {
                    const num = parseFloat(value);
                    const isFloat = value.includes('.') || num % 1 !== 0;
                    if (isFloat) {
                        state.billing_details[e.target.name] = value;
                    } else {
                        if (value.length === 5) {
                            if (value.slice(-1) === ".") {
                                state.billing_details[e.target.name] = value;
                            } else {
                                return false;
                            }
                        }
                    }
                }
                else {
                    state.billing_details[e.target.name] = value;
                }
                setState({ ...state }, handleValidateCurrency(e.target.name, value));
            } else {
                state.billing_details[e.target.name] = e.target.value
                setState({ ...state }, handleValidateCurrency(e.target.name, value))
            }
        } else if (args == 'placement') {
            updateAddress(e, state, setState, error, resetAddressErrors, state.placement_details.address);
            state.placement_details[e.target.name] = e.target.value
            setState({ ...state })

        } else if (args == 'payRate') {
            if (index !== undefined && index !== false && index !== null && index !== '') {
                if (e.target.name == "to_hour") {
                    state.pay_rate_details.pay_details[index][e.target.name] = e.target.value;
                    state.pay_rate_details.pay_details[0]['from_hour'] = 0;
                    state.pay_rate_details.pay_details[index + 1]['from_hour'] = e.target.value !== "" ? Number(e.target.value) + 1 : "";
                    if (state.pay_rate_details.pay_details.length > 1) {
                        for (let i = index; i < state.pay_rate_details.pay_details.length; i++) {
                            if (i + 1 !== state.pay_rate_details.pay_details.length) {
                                let val = state.pay_rate_details.pay_details[i + 1][e.target.name] === '' ? 0 : state.pay_rate_details.pay_details[i + 1][e.target.name]
                                if (val > state.pay_rate_details.pay_details[i][e.target.name]) {
                                    let length = i + 1
                                    for (let y = length; y <= state.pay_rate_details.pay_details.length; y++) {
                                        if (state.pay_rate_details.pay_details.length > y) {
                                            state.pay_rate_details.pay_details[y][e.target.name] = ''
                                            state.pay_rate_details.pay_details[y]['from_hour'] = ''
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    if (docError.length === 0) {
                        for (let i = 0; i <= index + 1; i++) {
                            docError.push({ from_hour: '' })
                        }
                        docError.filter(value => JSON.stringify(value) !== '{}');
                    }
                    if (docError[index + 1] !== undefined) {
                        docError[index + 1]['from_hour'] = '';
                    }
                } else if (e.target.name === "value") {
                    if (state.pay_rate_details.pay_config_type == 1) {
                        if (e.target.value > 100) {
                            addWarningMsg('Percentage is not allowed more than 100%');
                            return false;
                        } else {
                            state.pay_rate_details.pay_details[index][e.target.name] = e.target.value;
                        }
                    } else { // eslint-disable-next-line
                        var value = e.target.value.trim();
                        // value = value.replace("$", "");
                        if (value.length > 4) {
                            const num = parseFloat(value);
                            const isFloat = value.includes('.') || num % 1 !== 0;
                            if (isFloat) {
                                state.pay_rate_details.pay_details[index][e.target.name] = value;
                            } else {
                                if (value.length === 5) {
                                    if (value.slice(-1) === ".") {
                                        state.pay_rate_details.pay_details[index][e.target.name] = value;
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        } else {
                            state.pay_rate_details.pay_details[index][e.target.name] = value;
                        }
                    }
                    state.pay_rate_details.pay_details[index][e.target.name] = e.target.value;
                } else {
                    state.pay_rate_details.pay_details[index][e.target.name] = e.target.value;
                }
                setState({ ...state }, handleValidatePayConfig(e.target, index));
            } else {
                if (e.target.name === "pay_type") {
                    state.pay_rate_details[e.target.name] = e.target.value;
                    if (e.target.value === 2) {
                        state.pay_rate_details.pay_config_type = '';
                        state.pay_rate_details.ot_pay_config_type = "";
                        state.pay_rate_details.ot_pay_config_value = "";
                        state.pay_rate_details.ot_pay_config_multi = "";
                        state.pay_rate_details.pay_details = [];
                        setState({ ...state }, handleValidate(e));
                        setDocError([]);
                        setError({});
                    } else if (e.target.value === 1) {
                        state.pay_rate_details.pay_config_type = 1;
                        state.pay_rate_details.annual_pay = "";
                        state.pay_rate_details.pay_details = [{ from_hour: "0", to_hour: "", value: "" }];
                        setState({ ...state }, handleValidate(e));
                        error['annual_pay'] = ''
                        setError({});
                    } else {
                        setState({ ...state }, handleValidate(e));
                        error['annual_pay'] = "";
                        setError({ ...error });
                        setDocError([]);
                    }
                } else if (e.target.name === "annual_pay" || e.target.name == "ot_pay_config_value") {
                    let value = e.target.value.trim();
                    value = value.replace(currency, "")
                    if (e.target.name !== "annual_pay" && value.length > 4) {
                        const num = parseFloat(value);
                        const isFloat = value.includes('.') || num % 1 !== 0;
                        if (isFloat) {
                            state.pay_rate_details[e.target.name] = value;
                        } else {
                            if (value.length === 5) {
                                if (value.slice(-1) === ".") {
                                    state.pay_rate_details[e.target.name] = value;
                                } else {
                                    return false;
                                }
                            }
                        }
                    }
                    else if (e.target.name == "annual_pay") {
                        if (value.length > 9) {
                            const num = parseFloat(value);
                            const isFloat = value.includes('.') || num % 1 !== 0;
                            if (isFloat) {
                                state.pay_rate_details[e.target.name] = value;
                            } else {
                                if (value.length === 10) {
                                    if (value.slice(-1) === ".") {
                                        state.pay_rate_details[e.target.name] = value;
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        } else {
                            state.pay_rate_details[e.target.name] = value;
                        }
                        setState({ ...state }, handleValidateCurrency(e.target.name, value));
                    }
                    else {
                        state.pay_rate_details[e.target.name] = value;
                    }
                    state.pay_rate_details[e.target.name] = value;
                    setState({ ...state }, handleValidateCurrency(e.target.name, value));
                } else if (e.target.name === 'ot_pay_config_multi') {
                    if (e.target.value > 99.99) {
                        addWarningMsg('OT Pay Rate Multiplier should be less than 100');
                        return false;
                    } else {
                        state.pay_rate_details[e.target.name] = e.target.value;
                        setState({ ...state }, handleValidate(e));
                    }
                }
                else {
                    state.pay_rate_details[e.target.name] = e.target.value;
                    setState({ ...state }, handleValidate(e));
                }
            }
        } else if (args == 'ts') {
            if (e.target.name === 'is_timesheet_attachment_mandatory') {
                state.timesheet_details[e.target.name] = e.target.checked
                setState({ ...state })
            } else if (e.target.name == "timesheet_cycle") {
                state.timesheet_details[e.target.name] = e.target.value;
                state.timesheet_details.timesheet_start_day = "Monday";
                setState({ ...state });
                error.timesheet_start_day = "";
                setError({ ...error });
            } else {
                state.timesheet_details[e.target.name] = e.target.value
                setState({ ...state })
            }
        }
        handleValidate(e);
    }

    var type = 'add'

    const fetchZipcodeData = useZipcodeAPICall({ setState, setError, state, type });

    const changeHandlerAddress = (e, args) => {
        handleChangeAddress(e, args, state.placement_details.address, state, setState, error, setError);
        if (e.target.name == 'zip_code') {
            fetchZipcodeData(e.target.value, args)
        }
    }


    const handleValidatePayConfig = (e, index) => {
        let input = e;
        let error = docError.length > 0 ? (docError ? docError[index] : docError) : docError;
        for (var k = 0; k <= index; k++) {
            docError.push({});
        }
        let s1 = docError.length > 0 ? [...docError] : [{ ...docError }];
        switch (input.name || input.tagName) {
            case "to_hour":
                error.to_hour = validate_toHours(input.value, state.pay_rate_details.pay_details[index].from_hour);
                break;
            case "value":
                error.value = validates_float(input.value);
                if (error.value == "" && parseInt(input.value) <= 0) {
                    error.value = "The value should be greater than 0"
                }
                break;
            default:
                break;
        }
        setDocError(s1);
    }

    const handleValidateCurrency = (name, value) => {
        switch (name) {
            case "bill_rate":
                error.bill_rate = float_validation(value);
                if (error.bill_rate == '' && parseInt(value) <= 0) {
                    error.bill_rate = 'The rate should be greater than 0'
                }
                break
            case "ot_bill_rate":
                error.ot_bill_rate = value !== "" ? validates_handlechange_float(value) : "";
                if (error.ot_bill_rate == '' && value !== "" && parseInt(value) <= 0) {
                    error.ot_bill_rate = 'The rate should be greater than 0'
                }
                break
            case "annual_pay":
                error.annual_pay = float_validation(value)
                if (error.annual_pay == "" && parseInt(value) <= 0) {
                    error.annual_pay = 'The annual pay should be greater than 0'
                }
                break
            case "ot_pay_config_value":
                error.ot_pay_config_value = float_validation(value)
                break
            case "ot_pay_config_multi":
                error.ot_pay_config_multi = float_validation(value)
                break
            default:
                break
        }
        setError({ ...error })
    }

    const handleChangeTime = (e) => {
        let input = e.target.value.replace(/\D/g, "").substring(0, 5);
        const first = input.substring(0, 2);
        const second = input.substring(2, 5);
        if (input.length > 2) {
            state.timesheet_details.default_hours = `${first}:${second}`
            setState({ ...state });
        } else {
            state.timesheet_details.default_hours = input
            setState({ ...state });
        }
        if (input.length > 2) {
            var mm = parseInt(second);
            if (mm > 59) {
                if (first < 23) {
                    var sec = second - 60;
                    var fOne = parseInt(first) + 1;
                    if (fOne < 10) {
                        state.timesheet_details.default_hours = `0${fOne}:${sec}`
                        setState({ ...state });
                    } else {
                        state.timesheet_details.default_hours = `${fOne}:${sec}`
                        setState({ ...state });
                    }
                    handleValidate(e);
                } else {
                    state.timesheet_details.default_hours = `${first}:${59}`
                    setState({ ...state });
                    handleValidate(e);
                }
            } else {
                state.timesheet_details.default_hours = `${first}:${second}`
                setState({ ...state });
                handleValidate(e);
            }
        } else if (input.length >= 0) {
            var hh = parseInt(input);
            if (hh > 23) {
                state.timesheet_details.default_hours = "23";
            } else {
                state.timesheet_details.default_hours = input;
            }
            setState({ ...state });
            handleValidate(e);
        }
    };


    // Handle validations
    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "client_id":
                error.client_id = validate_emptyField(input.value);
                break;
            case "client_contact_id":
                error.client_contact_id = validate_emptyField(input.value);
                break;
            case "end_client_id":
                error.end_client_id = validate_emptyField(input.value);
                break;
            case "end_client_contact_id":
                error.end_client_contact_id = empty_fields(input.value);
                break;
            case "job_title_id":
                error.job_title_id = validate_emptyField(input.value);
                break;
            case "work_email_id":
                error.work_email_id = empty_Email_id(input.value);
                break;
            case "address_line_one":
                error.address_line_one = address_validation(input.value);
                break;
            case "address_line_two":
                error.address_line_two = validate_empty_address(input.value);
                break;
            case "state_id":
                error.state_id = validate_emptyField(input.value);
                break;
            case "city":
                error.city = validate_city(input.value);
                break;
            case "zip_code":
                error.zip_code = validate_zipcode(input.value, state.placement_details.country_id);
                break;
            case "effective_from":
                error.effective_from = validate_emptyField(input.value);
                break;
            case "timesheet_cycle":
                error.timesheet_cycle = validate_emptyField(input.value);
                break;
            case "timesheet_start_day":
                error.timesheet_start_day = validate_emptyField(input.value);
                break;
            case "default_hours":
                error.default_hours = validate_emptyField(input.value);
                break;
            case "timesheet_start_date":
                error.timesheet_start_date = validate_emptyField(input.value);
                break;
            case "placed_employee_id":
                error.placed_employee_id = validate_emptyField(input.value);
                break;
            case "notice_period":
                error.notice_period = empty_integer(input.value);
                break;
            case "start_date":
                error.start_date = validate_emptyField(input.value);
                break;
            case "work_location_type":
                error.work_location_type = validate_emptyField(input.value);
                break;
            case "end_date":
                error.end_date = validate_emptyField(input.value);
                break;
            case "work_location_address_line_one":
                error.work_location_address_line_one = validate_emptyField(input.value);
                break;
            case "work_location_address_line_two":
                error.work_location_address_line_two = validate_emptyField(input.value);
                break;
            case "work_location_country_id":
                error.work_location_country_id = validate_emptyField(input.value);
                break;
            case "work_location_state_id":
                error.work_location_state_id = validate_emptyField(input.value);
                break;
            case "work_location_city":
                error.work_location_city = empty_city(input.value);
                break;
            case "work_location_zipcode":
                error.work_location_zipcode = empty_zipcode(input.value);
                break;
            case "pay_type":
                error.pay_type = validate_emptyField(input.value);
                break;
            case "ot_pay_config_multi":
                error.ot_pay_config_multi = validates_float(input.value);
                break;
            case "lca_amount":
                error.lca_amount = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        let s1 = { ...error };
        setError(s1);
    }

    //ValidateAll Functions 
    const validateAll = async () => {

        let { client_details, billing_details, placement_details, pay_rate_details, timesheet_details } = state;
        let errors = {};
        console.log(client_details.lca_amount, 'client_details.lca_amount')
        errors.client_id = value == 0 ? validate_emptyField(client_details.client_id) : '';
        errors.lca_amount = ['H-1B', 'H-1B1', 'E3'].includes(location?.state?.empData?.visa_type) ? client_details.lca_amount == 0 ? ErrorMessages.greaterThanZero : validate_emptyField(client_details.lca_amount) : ''
        errors.client_contact_id = value == 0 ? validate_emptyField(client_details.client_contact_id) : '';
        errors.end_client_contact_id = value == 0 ? empty_fields(client_details.end_client_contact_id) : '';
        errors.bill_rate = value == 0 ? float_validation(billing_details.bill_rate) == "" ? parseInt(billing_details.bill_rate) <= 0 ? "The rate should be greater than 0" : "" : float_validation(billing_details.bill_rate) : '';
        errors.ot_bill_rate = value == 0 ? (validates_handlechange_float(billing_details.ot_bill_rate) == "" && billing_details.ot_bill_rate != "") ? parseInt(billing_details.ot_bill_rate) <= 0 ? "The rate should be greater than 0" : "" : validates_handlechange_float(billing_details.ot_bill_rate) : '';
        // errors.effective_from = value == 0 ? moment(billing_details.effective_from).isBefore(moment(employeeData.date_of_joining)) ? 'Please select a date that is greater than Joining date' : (lastWorkingDay !== '' || lastWorkingDay !== 'NaN') && moment(billing_details.effective_from).isAfter(moment(lastWorkingDay, dateFormat())) ? 'Please select a date prior to last working date' : validate_emptyField(billing_details.effective_from) : '';
        errors.effective_from =
            value == 0
                ? moment(billing_details.effective_from, dateFormat()).isBefore(moment(employeeData.date_of_joining, dateFormat()))
                    ? 'Placement start date should not go beyond  Employee Joining date'
                    : (lastWorkingDay !== '' || lastWorkingDay !== 'NaN') && moment(billing_details.effective_from, dateFormat()).isAfter(moment(lastWorkingDay, dateFormat()))
                        ? 'Placement start date should not be greater than Employee Last working date'
                        : validate_emptyField(billing_details.effective_from)
                : "";

        errors.job_title_id = value == 1 ? validate_emptyField(placement_details.job_title_id) : '';
        errors.work_email_id = value == 1 ? empty_Email_id(placement_details.work_email_id) : '';
        errors.work_location_type = value == 1 ? validate_emptyField(placement_details.work_location_type) : '';
        errors.office_address_line_one = value == 1 ? (placement_details.work_location_type == 'In-Office' || placement_details.work_location_type == 'Hybrid') ? address_validation(placement_details.address.office.address_line_one) : '' : '';
        errors.office_address_line_two = value == 1 ? (placement_details.work_location_type == 'In-Office' || placement_details.work_location_type == 'Hybrid') ? placement_details.address.office.address_line_two == '' ? '' : validate_empty_address(placement_details.address.office.address_line_two) : '' : '';
        errors.office_city = value == 1 ? (placement_details.work_location_type == 'In-Office' || placement_details.work_location_type == 'Hybrid') ? validate_city(placement_details.address.office.city) : '' : '';
        errors.office_state_id = value == 1 ? (placement_details.work_location_type == 'In-Office' || placement_details.work_location_type == 'Hybrid') ? validate_emptyField(placement_details.address.office.state_id) : '' : '';
        errors.office_zip_code = value == 1 ? (placement_details.work_location_type == 'In-Office' || placement_details.work_location_type == 'Hybrid') ? await checkZipcode(placement_details.address.office.zip_code) : '' : '';
        errors.remote_address_line_one = value == 1 ? (placement_details.work_location_type == 'Remote' || placement_details.work_location_type == 'Hybrid') ? address_validation(placement_details.address.remote.address_line_one) : '' : '';
        errors.remote_address_line_two = value == 1 ? (placement_details.work_location_type == 'Remote' || placement_details.work_location_type == 'Hybrid') ? placement_details.address.remote.address_line_two == '' ? '' : validate_empty_address(placement_details.address.remote.address_line_two) : '' : '';
        errors.remote_city = value == 1 ? (placement_details.work_location_type == 'Remote' || placement_details.work_location_type == 'Hybrid') ? validate_city(placement_details.address.remote.city) : '' : '';
        errors.remote_state_id = value == 1 ? (placement_details.work_location_type == 'Remote' || placement_details.work_location_type == 'Hybrid') ? validate_emptyField(placement_details.address.remote.state_id) : '' : '';
        errors.remote_zip_code = value == 1 ? (placement_details.work_location_type == 'Remote' || placement_details.work_location_type == 'Hybrid') ? await checkZipcode(placement_details.address.remote.zip_code) : '' : '';
        errors.pay_type = value == 2 ? validate_emptyField(pay_rate_details.pay_type) : '';
        errors.ot_pay_config_value = value == 2 && (state.pay_rate_details.pay_type == 1 && state.pay_rate_details.ot_pay_config_type == 2) ? float_validation(pay_rate_details.ot_pay_config_value) : '';
        errors.ot_pay_config_multi = value == 2 && (state.pay_rate_details.pay_type == 1 && state.pay_rate_details.ot_pay_config_type == 3) ? float_validation(pay_rate_details.ot_pay_config_multi) : '';
        errors.annual_pay = value == 2 && state.pay_rate_details.pay_type == 2 ? float_validation(pay_rate_details.annual_pay) == "" ? parseInt(pay_rate_details.annual_pay) <= 0 ? "The annual pay should be greater than 0" : "" : float_validation(pay_rate_details.annual_pay) : '';
        errors.ot_pay_config_type = value == 2 && state.pay_rate_details.pay_type == 1 ? employeeData.employment_type == 'Consultant' ? validate_emptyField(pay_rate_details.ot_pay_config_type) : '' : '';
        errors.timesheet_cycle = value == 3 ? validate_emptyField(timesheet_details.timesheet_cycle) : '';
        errors.default_hours = value == 3 ? validate_emptyField(timesheet_details.default_hours) : '';
        errors.timesheet_start_date = value == 3 ? validate_emptyField(timesheet_details.timesheet_start_date) : '';
        errors.timesheet_start_day = value == 3 ? timesheet_details.timesheet_cycle == 'Weekly' || timesheet_details.timesheet_cycle == 'Bi Weekly' ? validate_emptyField(timesheet_details.timesheet_start_day) : '' : '';
        setError(errors);
        return errors;
    }

    const validateMultiple = () => {
        let errors = {};
        let err = [];// eslint-disable-next-line
        state.pay_rate_details.pay_details.map((item, index) => {
            errors = {};
            errors.to_hour = activeStep == 2 ? state.pay_rate_details.pay_details.length !== (index + 1) ? validate_toHours(item.to_hour, item.from_hour) : '' : '';
            errors.value = activeStep == 2 ? validates_float(item.value) == "" ? parseInt(item.value) <= 0 ? 'The value should be greater than 0' : "" : validates_float(item.value) : '';
            err.push(errors)
        })
        return err;
    }

    // eslint-disable-next-line
    const validateDocErrors = (e, index) => {
        let input = e;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "document_type_id":
                error.document_type_id = validate_emptyField(input.value);
                break;
            case "document_name":
                error.document_name = validate_emptyField(input.value);
                break;
            case "new_document_id":
                error.new_document_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setErro(s2);
    }

    const defaultHoursApi = () => {
        PlacementApi.getDefaultHours().then((res) => {
            if (res.data.statusCode == 1003) {
                setDefaultHours(res.data.data.default_annual_hours);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const defaultTSApi = () => {
        PlacementApi.defaultTSgetApi().then((res) => {
            if (res.data.statusCode == 1003) {
                if (res.data.data.length > 0) {
                    state.timesheet_details.approval_levels = res.data.data[0].approval_levels
                    setState({ ...state });
                }
            }
        })
    }

    const placementStoreApi = () => {
        addLoader(true);
        setSubmitLoader(true);
        PlacementApi.storePlacement(state).then((res => {
            if (res.data.statusCode == 1003) {
                setSubmitLoader(false);
                removeLoader();
                setOpenSuccess(true);
                setOpenDefaultTS(false);
            } else {
                setSubmitLoader(false);
                removeLoader();
                addErrorMsg(res.data.message);
            }
        }))
    }

    const handleSubmit = async () => {
        if (duplicateId === (state.client_details.client_id !== '' && state.client_details.client_id)) {
            addErrorMsg('Placement Already Exists between the Employee and Client')
            return true
        }
        let errors = await validateAll();
        let multiErrors = validateMultiple();
        setState({ ...state });
        if (isValid(errors)) {
            if (value == 2) {
                defaultTSApi();
                if (state.pay_rate_details.pay_type == 1 && isValidMulti(multiErrors)) {
                    setValue(value + 1);
                    setActiveStep(activeStep + 1);
                    scrollToTop();
                    state.timesheet_details.timesheet_start_date = state.billing_details.effective_from;
                    state.timesheet_details.timesheet_start_day = "Monday";
                    setState({ ...state })
                } else if (state.pay_rate_details.pay_type == 2 && isValid(errors)) {
                    setValue(value + 1);
                    setActiveStep(activeStep + 1);
                    scrollToTop();
                    state.timesheet_details.timesheet_start_date = state.billing_details.effective_from;
                    state.timesheet_details.timesheet_start_day = "Monday";
                    setState({ ...state })
                }
                else {
                    let s1 = { error }
                    s1 = errors
                    setError(s1);
                    let s3 = { docError }
                    s3 = multiErrors
                    setDocError(s3);
                    addWarningMsg(ErrorMessages.genericMessage);
                }
            }
            else if (value == 3) {
                if (state.timesheet_details.approval_levels.length > 0) {
                    for (var i in state.timesheet_details.approval_levels) {
                        if (state.timesheet_details.approval_levels[i].approval_users.length == 0) {
                            state.timesheet_details.approval_levels.splice(i, 1);
                        }
                    }
                    setState({ ...state });
                    if (state.timesheet_details.approval_levels.length > 0) {
                        for (var k in state.timesheet_details.approval_levels) {
                            state.timesheet_details.approval_levels[k]['level'] = parseInt(k) + 1
                        }
                        setState({ ...state });
                    }
                }
                // if (deletedchips.length > 0) {
                //     state.timesheet_details.deleted_approver_ids = deletedchips;
                //     setState({ ...state });
                // }
                // if (deletedLevels.length > 0) {
                //     state.deleted_level_ids = deletedLevels;
                //     setState({ ...state });
                // }           
                if (dataExists) {
                    setOpenDefaultTS(true);
                } else {
                    state['employee_id'] = employeeData.id
                    if (state.timesheet_details.default_hours.includes(':')) { }
                    else {
                        state.timesheet_details.default_hours = `${state.timesheet_details.default_hours}:00`
                    }
                    state.billing_details['ot_bill_rate'] = state.billing_details.ot_bill_rate != '' ? state.billing_details.ot_bill_rate : state.billing_details.bill_rate
                    placementStoreApi()
                }
            }
            else {
                setValue(value + 1);
                setActiveStep(activeStep + 1);
                scrollToTop();
            }
        }
        else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            let s3 = { docError }
            s3 = multiErrors
            setDocError(s3);
            addWarningMsg(ErrorMessages.genericMessage);
        }
    }

    const back = () => {
        if (activeStep == 0) {
            setOpenCancel(true);
        } else {
            setActiveStep(activeStep - 1);
            setValue(value - 1);
            scrollToTop();
        }
    }

    const stepLabels = ['Client Details', 'Placement Details', 'Pay Rate Configuration', 'Timesheet Configuration']

    const handleDrop = (e) => {
        if (e.length < 6) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'png', 'jpg', 'jpeg'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("visa-document", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {
                                if (state.placement_details.supporting_documents.length < 5) {
                                    state.placement_details.supporting_documents.push({
                                        id: '',
                                        temp_document_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 5 files at a time. Please remove excess files and try again')
                                }
                                setState({ ...state })
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or Images only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 5 files at a time. Please remove excess files and try again')
        }
    }

    const [focusIndex, setFocusIndex] = useState(null)

    const handleFocus = (index) => {
        setFocusIndex(index);
    };

    const handleBlur = () => {
        setFocusIndex(null);
    };

    useEffect(() => {
        if (employeeData.id) {
            EmployeesApi.getEmployee(employeeData.id).then(res => {
                setLastWorkingDay(res.data?.data?.last_working_day || '')
            })
        }
    }, [employeeData])


    return (
        <Box>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_create" && item.is_allowed == true))) ?
                    <Grid container lg={12} xs={12} md={12} justifyContent='center' pb={5}>
                        <Grid item container lg={10} md={11} sm={11} xs={12} justifyContent='center' position='fixed' zIndex={1} sx={{ background: '#FFFFFF' }}>
                            <Grid item lg={10} md={10} sm={10} xs={12} mt={1} textAlign='center' p={'30px 0px 25px 0px !important'}>
                                <Stepper activeStep={activeStep}
                                    connector={<BrownMnCustomisedConnector />}
                                >
                                    {
                                        stepLabels.map((item) => (
                                            <Step>
                                                <BrownMnColorlibStepLabel StepIconComponent={clientandEndclientStepper}>
                                                    <Text BrowmnMnStepperText>{item}</Text>
                                                </BrownMnColorlibStepLabel>
                                            </Step>
                                        ))
                                    }
                                </Stepper>
                            </Grid>
                        </Grid>
                        {/* Form  */}
                        <Grid item lg={6} md={8} sm={10} xs={12} pt={9}>
                            <SwipeableViews index={value} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} onChangeIndex={handleChangeIndex}>
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }} >
                                        <CardHeader sx={{ padding: '15px 0px 0px 25px !important' }} title={
                                            <Text blackHeader1>Employee Details</Text>
                                        } />
                                        <CardContent
                                            TransitionComponent={Transition}
                                            sx={{ padding: '20px 30px 30px 30px !important' }}>
                                            {
                                                activeStep == 0 &&
                                                <Grid item container lg={12} spacing={2}>
                                                    <Grid item container spacing={3}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'full_name',
                                                                        value: location?.state?.empData?.full_name,
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='Employee Name'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'reference_id',
                                                                        value: location?.state?.empData?.reference_id,
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='Employee ID'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        {
                                                            ['H-1B', 'H-1B1', 'E3'].includes(location?.state?.empData?.visa_type) ?
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Box pt={1}>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'visa_type',
                                                                                value: location?.state?.empData?.visa_type,
                                                                            }}
                                                                            disabled={true}
                                                                            clientInput
                                                                            labelText='Visa Type'
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                : ''}
                                                        {
                                                            ['H-1B', 'H-1B1', 'E3'].includes(location?.state?.empData?.visa_type)
                                                                ?
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Box pt={1}>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'lca_amount',
                                                                                value: currency + '' + state.client_details.lca_amount,
                                                                                inputProps: {
                                                                                    maxLength: 14, // Maximum length for "9999999999.99"
                                                                                    pattern: "\\d*\\.?\\d{0,2}", // Allow numbers with up to 2 decimals
                                                                                },
                                                                            }}
                                                                            handleChange={(e) => changeHandler(e, 'clients')}
                                                                            onKeyPress={onNumberWithTwoDecimalOnlyChangeCurrency}
                                                                            clientInput
                                                                            labelText='Minimum Annual Wage (As per LCA)'
                                                                            error={error.lca_amount}
                                                                        />
                                                                        {
                                                                            error.lca_amount ?
                                                                                <Text red>{error.lca_amount ? error.lca_amount : ''}</Text> : ''
                                                                        }
                                                                    </Box>
                                                                    <a href='https://flag.dol.gov/wage-data/wage-search' rel="noreferrer" target='_blank'>
                                                                        <Text smallBlue sx={{ textDecoration: 'underline', paddingTop: '10px', cursor: 'pointer' }}>Check minimum wage here</Text>
                                                                    </a>
                                                                </Grid>
                                                                : ''
                                                        }

                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Text blackHeader1>Client Details</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Search
                                                                    name='client_id'
                                                                    value={state.client_details.client_id}
                                                                    options={clientList}
                                                                    label='Client Name'
                                                                    onChange={(e) => changeHandler(e, 'clients')}
                                                                    buttonName='New Client'
                                                                    error={error.client_id}
                                                                    buttonHandler={() => {
                                                                        navigate('/addClient', { state: { flow: 'placement' } })
                                                                    }}
                                                                    showButton
                                                                />
                                                            </Box>
                                                            {
                                                                error.client_id ?
                                                                    <Text red>{error.client_id ? error.client_id : ''}</Text> : ''
                                                            }
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'client_reference_id',
                                                                        value: state.client_details.client_reference_id,
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='Client ID'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Search
                                                                    name='client_contact_id'
                                                                    value={state.client_details.client_contact_id}
                                                                    disabled={state.client_details.client_id === ''}
                                                                    options={clientContList}
                                                                    label='Contact'
                                                                    onChange={(e) => changeHandler(e, 'clients')}
                                                                    buttonName='New Contact'
                                                                    error={error.client_contact_id && state.client_details.client_id !== '' && error.client_contact_id}
                                                                    buttonHandler={() => {
                                                                        LocalStorage.removeRedirectedModule();
                                                                        LocalStorage.removeNotificationId();
                                                                        navigate('/clients/clients-user-profile', { state: { flow: 'placementContact', data: { id: state.client_details.client_id } } })
                                                                    }}
                                                                    showButton={clientContList.length < 5}
                                                                />
                                                            </Box>
                                                            {
                                                                error.client_contact_id && state.client_details.client_id !== '' ?
                                                                    <Text red>{error.client_contact_id ? error.client_contact_id : ''}</Text> : ''
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Text blackHeader1>End Client Details</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Search
                                                                    name='end_client_id'
                                                                    value={state.client_details.end_client_id}
                                                                    options={endClientList}
                                                                    label='End Client Name (Optional)'
                                                                    onChange={(e) => changeHandler(e, 'clients')}
                                                                    buttonName='New End-Client'
                                                                    buttonHandler={() => {
                                                                        navigate('/addEnd-Client', { state: { flow: 'placement' } })
                                                                    }}
                                                                    showButton
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'endclient_reference_id',
                                                                        value: state.client_details.endclient_reference_id,
                                                                    }}
                                                                    disabled={true}
                                                                    clientInput
                                                                    labelText='End Client ID'
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Search
                                                                    name='end_client_contact_id'
                                                                    value={state.client_details.end_client_contact_id}
                                                                    options={endClientContList}
                                                                    label='Contact (Optional)'
                                                                    onChange={(e) => changeHandler(e, 'clients')}
                                                                    disabled={state.client_details.end_client_id === ''}
                                                                    error={error.end_client_contact_id}
                                                                    buttonName='New Contact'
                                                                    buttonHandler={() => {
                                                                        navigate('/clients/end-clients-user-profile', { state: { flow: 'placementContact', id: state.client_details.end_client_id } })
                                                                    }}
                                                                    showButton={endClientContList.length < 5}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Text blackHeader1>Billing Details</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Search
                                                                    name='bill_type'
                                                                    value={state.billing_details.bill_type}
                                                                    options={billType}
                                                                    label='Bill Rate Type'
                                                                    onChange={(e) => changeHandler(e, "billing")}
                                                                    disabled={true}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'bill_rate',
                                                                        value: currency + '' + state.billing_details.bill_rate,
                                                                    }}
                                                                    handleChange={(e) => changeHandler(e, 'billing')}
                                                                    onKeyPress={onNumberFloatCurrency}
                                                                    clientInput
                                                                    error={error.bill_rate}
                                                                    labelText='Bill Rate per Hour ($)'
                                                                />
                                                                {
                                                                    error.bill_rate ?
                                                                        <Text red>{error.bill_rate ? error.bill_rate : ''}</Text> : ''
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'ot_bill_rate',
                                                                        value: currency + '' + state.billing_details.ot_bill_rate,
                                                                    }}
                                                                    onKeyPress={onNumberFloatCurrency}
                                                                    clientInput
                                                                    error={error.ot_bill_rate}
                                                                    handleChange={(e) => changeHandler(e, "billing")}
                                                                    labelText='OT Bill Rate per Hour ($) (Optional)'
                                                                />
                                                                {
                                                                    error.ot_bill_rate ?
                                                                        <Text red>{error.ot_bill_rate ? error.ot_bill_rate : ''}</Text> : ''
                                                                }
                                                                <Text smallLabel sx={{ paddingTop: '8px' }}>Incase OT Bill field is empty, bill rate will be considered for further calculation by default</Text>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Box pt={1}>
                                                                <Date
                                                                    labelText='Start Date'
                                                                    name='effective_from'
                                                                    value={state.billing_details.effective_from}
                                                                    maxDate={lastWorkingDay}
                                                                    onChange={(value => dateChange(value, 'effective_from'))}
                                                                    height='54px'
                                                                    error={error.effective_from}
                                                                    minDate={employeeData ? employeeData.date_of_joining : ""}
                                                                />
                                                                {
                                                                    error.effective_from ?
                                                                        <Text red>{error.effective_from ? error.effective_from : ''}</Text> : ''
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }} >
                                        <CardHeader sx={{ padding: '15px 0px 0px 25px !important' }} title={
                                            <Text blackHeader1>Placement Details</Text>
                                        } />
                                        <CardContent
                                            TransitionComponent={Transition}
                                            sx={{ padding: '20px 30px 30px 30px !important' }}>
                                            {
                                                activeStep == 1 &&
                                                <Grid container lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                    <Grid item container spacing={3}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Search
                                                                name='job_title_id'
                                                                value={state.placement_details.job_title_id}
                                                                options={jobTitles}
                                                                label='Job Title'
                                                                onChange={(e) => changeHandler(e, 'placement')}
                                                                buttonHandler={() => setOpenJobtitle(true)}
                                                                buttonName='New Job Title'
                                                                showButton
                                                                error={error.job_title_id}
                                                            />
                                                            {
                                                                error.job_title_id ?
                                                                    <Text red>{error.job_title_id ? error.job_title_id : ''}</Text> : ''
                                                            }
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'work_email_id',
                                                                    value: state.placement_details.work_email_id
                                                                }}
                                                                handleChange={(e) => changeHandler(e, 'placement')}
                                                                clientInput
                                                                labelText='Work Email ID (Optional)'
                                                                error={error.work_email_id}
                                                            />
                                                            <Text errorText> {error.work_email_id ? error.work_email_id : ""}</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Search
                                                                name='recruiter_id'
                                                                value={state.placement_details.recruiter_id}
                                                                options={recruitersList}
                                                                label='Recruiter (Optional)'
                                                                onChange={(e) => changeHandler(e, 'placement')}
                                                                buttonHandler={() => setOpenRecruiter(true)}
                                                                buttonName='New Recruiter'
                                                                showButton
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Box display='flex' flexDirection='row' gap={2}>
                                                                <Text blackHeader1>Work Location</Text>
                                                                {/* <BlackToolTip arrow title={
                                                                    <Text mediumWhite sx={{ padding: '6px !important' }}>If you are working remotely<br /> Please add your home<br /> address</Text>
                                                                } placement="bottom">
                                                                    <img src={InfoIcon} alt="InfoIcon" className={classes.infoIcon} />
                                                                </BlackToolTip> */}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Search
                                                                name='work_location_type'
                                                                value={state.placement_details.work_location_type}
                                                                onChange={(e) => changeHandler(e, 'placement')}
                                                                options={locationType}
                                                                label='Work Location'
                                                                error={error.work_location_type}
                                                            />
                                                            {
                                                                error.work_location_type ?
                                                                    <Text red>{error.work_location_type ? error.work_location_type : ''}</Text> : ''
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item spacing={3}>
                                                        <Worklocation error={error} columnSize={6} work_location_type={state.placement_details.work_location_type} changeHandlerAddress={changeHandlerAddress} state={state.placement_details} countries={countries} states={states} />
                                                    </Grid>

                                                    <Grid item container spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Text blackHeader1>Add Placement Supporting Details</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className={classes.dropZone}>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <Grid container spacing={1} textAlign='center' >
                                                                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                                                                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                                        <Text largeBlack>Drag & Drop Documents</Text>
                                                                                        <Text smallGrey>or</Text>
                                                                                        <Text smallBlue1 className={classes.clickHere} sx={{ cursor: 'pointer' }}>Click Here</Text>
                                                                                        <Text largeBlack>To Upload</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                                                    <Text mediumOrange>Add your PO, MSA, COI etc..</Text>
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'8px'}>
                                                                                    <img src={download} alt='download' />
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                                                    <Text smallGreyFont>Document Size 25MB, Format - PDF, Images</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                        state?.placement_details?.supporting_documents.length > 0 ?
                                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                            <Text smallLabel sx={{ font: "12px Quicksand !important" }}>Uploaded Documents</Text>
                                                            <Grid item container lg={12} md={12} columnGap={1.5}>
                                                            {
                                                                state?.placement_details?.supporting_documents.map((item, index) => (
                                                                <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                                    <Text onClick={() => window.open(item.document_url)} sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important`,cursor:"pointer !important" }}>
                                                                    {item.document_name.length > 16 ?
                                                                        <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                        </BlackToolTip>
                                                                        :
                                                                        <>
                                                                        {item.document_name}
                                                                        </>}
                                                                    </Text>
                                                                    <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { deleteChip(index) }} />
                                                                </Box>
                                                                ))
                                                            }
                                                            </Grid>
                                                        </Box> :
                                                        <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                            <Text smallLabel sx={{ font: "14px Quicksand !important" }}>Uploaded Documents</Text>
                                                            <Grid item container lg={12} columnGap={1.5}>
                                                            </Grid>
                                                        </Box>
                                                    }
                                                            {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                                                <div style={{ width: '100%', paddingTop: state.placement_details.supporting_documents.length > 0 ? '12px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center", }}>
                                                                    <Autocomplete
                                                                        clearIcon={false}
                                                                        options={[]}
                                                                        freeSolo
                                                                        multiple
                                                                        onKeyPress={(e) => { e.preventDefault() }}
                                                                        // onChange={(e) => uploadDocs(e)}
                                                                        value={state.placement_details.supporting_documents}
                                                                        renderTags={(value, props) =>
                                                                            value.map((option, index) => (
                                                                                option.document_name.length > 16 ?
                                                                                    <BlackToolTip arrow placement="top" key={index} title={option.document_name}>
                                                                                        <Chip label={option.document_name.length > 16 ? capitalizeAndAddSpace(option.document_name.slice(0, 16)) + capitalizeAndAddSpace(option.document_name.length > 16 ? "..." : "") : capitalizeAndAddSpace(option.document_name)} {...props({ index })}
                                                                                            onClick={() => window.open(option.document_url)}
                                                                                            onDelete={() => deleteChip(index)}
                                                                                            className={classes.chipLabel}
                                                                                        />
                                                                                    </BlackToolTip> :
                                                                                    <Chip label={capitalizeAndAddSpace(option.document_name)} {...props({ index })}
                                                                                        onClick={() => window.open(option.document_url)}
                                                                                        onDelete={() => deleteChip(index)}
                                                                                        className={classes.chipLabel}
                                                                                    />

                                                                            ))
                                                                        }
                                                                        renderInput={(params) => <TextField label="Uploaded Documents (Optional)" autoComplete="off" {...params} />}
                                                                        sx={{
                                                                            width: '100%',
                                                                            "& .MuiInputBase-root": {
                                                                                cursor: state.placement_details.supporting_documents.length > 0 ? "pointer" : "default",
                                                                            },
                                                                            "& .MuiInputBase-input": {
                                                                                cursor: state.placement_details.supporting_documents.length > 0 ? "pointer" : "default",
                                                                            },
                                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                                border: "none"
                                                                            },
                                                                            "& .MuiAutocomplete-endAdornment": {
                                                                                display: "none"
                                                                            },
                                                                            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                                                transform: "translate(10px, 16px) scale(1);"
                                                                            },
                                                                            "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                color: "#737373",
                                                                                font: "14px Quicksand !important",
                                                                                fontWeight: 400,
                                                                            },
                                                                            "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                color: `${state.placement_details.supporting_documents.length > 0 ? '#0C75EB !important' : '#737373'}`,
                                                                                font: "14px Quicksand !important",
                                                                                fontWeight: 400,
                                                                            },
                                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                color: "#737373",
                                                                                font: "15px Quicksand !important",
                                                                                fontWeight: 500,
                                                                            },
                                                                            "&.Mui-focused .MuiInputLabel-outlined": {
                                                                                color: "#737373",
                                                                                font: "10px Quicksand !important",
                                                                                fontWeight: 400,
                                                                                transform: state.placement_details.supporting_documents.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }} >
                                        <CardHeader
                                            sx={{ padding: '15px 0px 0px 30px !important' }}
                                            title={
                                                <Text blackHeader1>Pay Rate Configuration</Text>
                                            }
                                        />
                                        <CardContent
                                            TransitionComponent={Transition}
                                            sx={{ minHeight: '200px !important' }}>
                                            {
                                                activeStep == 2 &&
                                                <Grid item container spacing={2} p={1} alignItems='center'>
                                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                                        <Select
                                                            name='pay_type'
                                                            value={state.pay_rate_details.pay_type}
                                                            onChange={(e) => changeHandler(e, 'payRate')}
                                                            options={payTypes}
                                                            label={'Pay Type'}
                                                            helperText={error.pay_type ? <Text red>{error.pay_type ? error.pay_type : ''}</Text> : ""}
                                                        />
                                                    </Grid>
                                                    {
                                                        state.pay_rate_details.pay_type == 2 ?
                                                            <>
                                                                <Grid item lg={5} md={5} sm={6} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'annual_pay',
                                                                            value: currency + '' + state.pay_rate_details.annual_pay,
                                                                            inputProps: { maxLength: 13 }
                                                                        }}
                                                                        handleChange={(e) => { changeHandler(e, 'payRate') }}
                                                                        onKeyPress={onNumberFloatCurrency}
                                                                        selectBesideInput
                                                                        labelText={`Annual Pay`}
                                                                        error={error.annual_pay}
                                                                    />
                                                                    <Box sx={{ height: '0px' }}>
                                                                        {error.annual_pay ? <Text red>{error.annual_pay ? error.annual_pay : ''}</Text> : ''}
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={12} xs={12} mt={2}>
                                                                    <Box display='flex' flexDirection='row' gap={1.5} className={classes.payrateNote}>
                                                                        <Box pt={'4px'}>
                                                                            <img src={blueinfo} alt='warning' />
                                                                        </Box>
                                                                        <Box textAlign='start'>
                                                                            {
                                                                                LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "working_hours_edit" && item.is_allowed == true)) ?
                                                                                    <Text mediumOverView>
                                                                                        The default hours / year for your organization is set to {defaultHours} which will be used to calculate the hourly rate on the basis of provided annual pay amount. To change the default hour / year configuration, <span className={classes.clickHereText} onClick={() => clickHandler()}>click here.</span>
                                                                                    </Text> :
                                                                                    <Text mediumOverView>
                                                                                        The default hours / year for your organization is set to {defaultHours} which will be used to calculate the hourly rate on the basis of provided annual pay amount. To change the default hour / year configuration, Please contact Organization Administrator.
                                                                                    </Text>
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                            :
                                                            state.pay_rate_details.pay_type == 1 ?
                                                                <>
                                                                    <Grid item lg={12} md={12} sm={12} mt={2}>
                                                                        <Text blackHeader1>Choose Pay Configuration</Text>
                                                                    </Grid>

                                                                    <Grid item lg={12} md={12} sm={12} pt={2}>
                                                                        <Box className={classes.btnBox1} gap={1}>
                                                                            <Grid item lg={6} md={6} sm={6}>
                                                                                <Button onClick={() => handleTabChange(1)} saveBtn className={state.pay_rate_details.pay_config_type == 1 ? classes.activeButton1 : classes.inActiveButton1}>Percentage</Button>
                                                                            </Grid>
                                                                            <Grid item lg={6} md={6} sm={6}>
                                                                                <Button onClick={() => handleTabChange(2)} saveBtn className={state.pay_rate_details.pay_config_type == 2 ? classes.activeButton1 : classes.inActiveButton1}>Value</Button>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                    {
                                                                        state.pay_rate_details.pay_details.length > 0 && state.pay_rate_details.pay_details.map((item, index) => (
                                                                            <Grid item container columnSpacing={3} mt={2} alignItems={"center"}>
                                                                                <Grid item lg={4} md={4} sm={4}>
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'from_hour',
                                                                                            value: index == 0 ? '0' : item.from_hour,
                                                                                        }}
                                                                                        disabled={true}
                                                                                        clientInput
                                                                                        labelText={`From Hours`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg={3.5} md={4} sm={4}>
                                                                                    <Input
                                                                                        formControlProps={{
                                                                                            fullWidth: true
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: 'to_hour',
                                                                                            value: state.pay_rate_details.pay_details.length == (index + 1) ? `Remainder` : item.to_hour,
                                                                                            onClick: state.pay_rate_details.pay_details.length == (index + 1) ? handleClick : null
                                                                                        }}
                                                                                        clientInput
                                                                                        onKeyPress={onNumberOnlyChange}
                                                                                        handleChange={(e) => changeHandler(e, 'payRate', index)}
                                                                                        labelText={`To Hours`}
                                                                                        error={docError[index] ? docError[index].to_hour : ''}
                                                                                    />
                                                                                    {docError.length > 0 ?
                                                                                        <Box sx={{ height: '0px' }}>
                                                                                            <Text red>{docError[index] ? docError[index].to_hour : ''}</Text>
                                                                                        </Box>
                                                                                        : ''}
                                                                                </Grid>
                                                                                <Grid item lg={3.5} md={4} sm={4}>
                                                                                    <FormControl fullWidth>
                                                                                        <TextField
                                                                                            label={state.pay_rate_details.pay_config_type == 1 ? "Percentage" : `Value`}
                                                                                            margin="dense"
                                                                                            type={"text"}
                                                                                            value={item.value}
                                                                                            onChange={(e) => changeHandler(e, 'payRate', index)}
                                                                                            onKeyPress={onFloatOnlyChange}
                                                                                            name={'value'}
                                                                                            autoComplete='off'
                                                                                            variant="filled"
                                                                                            onFocus={() => { handleFocus(index) }}
                                                                                            onBlur={handleBlur}
                                                                                            InputProps={{
                                                                                                startAdornment: state.pay_rate_details.pay_config_type == 2 && (<InputAdornment position="start"><Text mediumBlack14>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'}</Text></InputAdornment>),
                                                                                                endAdornment: state.pay_rate_details.pay_config_type == 1 && (<InputAdornment position="end"><Text mediumBlack14>%</Text></InputAdornment>),
                                                                                                sx: {
                                                                                                    borderRadius: '8px !important',
                                                                                                    border: docError[index] && docError[index].value ? '1px solid red !important' : focusIndex == index ? "1px solid #0C75EB !important" : `1px solid #C7CCD3 !important`,
                                                                                                    // border: (docError[index] && docError[index].value) ? `1px solid red !important` : `1px solid #C7CCD3 !important`,
                                                                                                    background: "#ffffff !important",
                                                                                                },
                                                                                                disableUnderline: true,
                                                                                                classes: { input: ((docError[index] && docError[index].value) && state.pay_config_type == 2) ? classes.error1 : ((docError[index] && docError[index].value) && state.pay_config_type == 1) ? classes.error : classes.clientInput }
                                                                                            }}
                                                                                            InputLabelProps={{
                                                                                                sx: {
                                                                                                    color: (docError[index] && docError[index].value) ? 'red !important' : "#737373 !important",
                                                                                                    font: '15px Quicksand !important',
                                                                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                                                                        color: (docError[index] && docError[index].value) ? 'red !important' : "#0C75EB !important",
                                                                                                        font: '15px Quicksand !important',
                                                                                                    },
                                                                                                },
                                                                                            }}
                                                                                        />

                                                                                    </FormControl>
                                                                                    {/* <Input
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    name: 'value',
                                                                                    value: item.value,
                                                                                    inputProps: { maxLength: 7 }
                                                                                }}
                                                                                handleChange={(e) => changeHandler(e, 'payRate', index)}
                                                                                onKeyPress={onFloatOnlyChange}
                                                                                clientInput
                                                                                labelText={state.pay_rate_details.pay_config_type == 1 ? "Percentage" : `Value`}
                                                                                error={docError[index] ? docError[index].value : ''}
                                                                            /> */}
                                                                                    {docError.length > 0 ?
                                                                                        <Box sx={{ height: '0px' }}>
                                                                                            <Text red>{docError[index] ? docError[index].value : ''}</Text>
                                                                                        </Box>
                                                                                        : ''}
                                                                                </Grid>
                                                                                {
                                                                                    state.pay_rate_details.pay_details.length > 1 &&
                                                                                    <Grid item lg={1} md={1} sm={1} >
                                                                                        <RemoveIcon onClick={() => handleDeleteLevelOpen(index)} className={classes.removeIcon} />
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                    {
                                                                        employeeData.employment_type == 'Consultant' ?
                                                                            <>
                                                                                <Grid item lg={12} md={12} sm={12} mt={2}>
                                                                                    <Text blackHeader1>OT Pay Rate Config Type</Text>
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} pt={1}>
                                                                                    <CustomRadioGroup mediumGrey2 options={options} value={state.pay_rate_details.ot_pay_config_type} onChange={handleRadio1Change} />
                                                                                    {error.ot_pay_config_type ? <Text red>{error.ot_pay_config_type ? error.ot_pay_config_type : ''}</Text> : ''}
                                                                                </Grid>
                                                                                <Grid item container columnSpacing={4} pt={3} alignItems='center'>
                                                                                    {
                                                                                        state.pay_rate_details.ot_pay_config_type == 3 &&
                                                                                        <>
                                                                                            <Grid item lg={4} md={4} sm={4}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'ot_pay_config_multi',
                                                                                                        value: state.pay_rate_details.ot_pay_config_multi,
                                                                                                        // inputProps: { maxLength: state.pay_rate_details.ot_pay_config_type == 3 ? 3 : 7 }
                                                                                                    }}
                                                                                                    handleChange={(e) => changeHandler(e, 'payRate')}
                                                                                                    onKeyPress={onNumberWithTwoDecimalOnlyChange}
                                                                                                    clientInput
                                                                                                    labelText={`OT Pay Rate Multiplier `}
                                                                                                    error={error.ot_pay_config_multi} />
                                                                                                {error.ot_pay_config_multi ? <Text red>{error.ot_pay_config_multi ? error.ot_pay_config_multi : ''}</Text> : ''}
                                                                                            </Grid>
                                                                                            <Grid item lg={4} md={4} sm={4}>
                                                                                                <BlackToolTip arrow title={
                                                                                                    <Text mediumWhite sx={{ padding: '6px !important' }}>Enter the multiplier to multiply the regular pay rate and calculate the OT pay rate</Text>
                                                                                                } placement="bottom">
                                                                                                    <img src={InfoIcon} alt="InfoIcon" className={classes.infoIcon} style={{ cursor: 'pointer' }} />
                                                                                                </BlackToolTip>
                                                                                            </Grid>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        state.pay_rate_details.ot_pay_config_type == 2 &&
                                                                                        <Grid item lg={4} md={4} sm={4}>
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'ot_pay_config_value',
                                                                                                    value: currency + '' + state.pay_rate_details.ot_pay_config_value,
                                                                                                    inputProps: { maxLength: 8 }
                                                                                                }}
                                                                                                disabled={state.pay_rate_details.ot_pay_config_type == 1 || state.pay_rate_details.ot_pay_config_type == 3 || state.pay_rate_details.ot_pay_config_type == ""}
                                                                                                clientInput
                                                                                                handleChange={(e) => { changeHandler(e, 'payRate') }}
                                                                                                error={error.ot_pay_config_value}
                                                                                                labelText={`OT Pay Rate`}
                                                                                            />
                                                                                            {error.ot_pay_config_value ? <Text red>{error.ot_pay_config_value ? error.ot_pay_config_value : ''}</Text> : ''}
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                            : ''}
                                                                </> : ""
                                                    }
                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value={value} index={3} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }} >
                                        <CardHeader sx={{ padding: '15px 0px 0px 30px !important' }} title={
                                            <Text blackHeader1>Timesheet Configuration</Text>
                                        } />
                                        <CardContent
                                            TransitionComponent={Transition}
                                        >
                                            {
                                                activeStep == 3 &&
                                                <Grid item container lg={12} spacing={3} p={1}>
                                                    <Grid item lg={6} md={6} sm={6}>
                                                        <Select
                                                            name='timesheet_cycle'
                                                            value={state.timesheet_details.timesheet_cycle}
                                                            onChange={(e) => changeHandler(e, 'ts')}
                                                            options={timesheetCycleDropdown}
                                                            label={'Timesheet Cycle'}
                                                            error={error.timesheet_cycle}
                                                        />
                                                        {
                                                            error.timesheet_cycle &&
                                                            <Text red>{error.timesheet_cycle ? error.timesheet_cycle : ''}</Text>
                                                        }
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'default_hours',
                                                                value: state.timesheet_details.default_hours,
                                                                inputProps: { maxLength: 5 }
                                                            }}
                                                            clientInput
                                                            handleChange={(e) => handleChangeTime(e)}
                                                            error={error.default_hours}
                                                            labelText={`Default Hours`}
                                                        />
                                                        <Text errorText> {error.default_hours ? error.default_hours : ""}</Text>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6}>
                                                        <Date
                                                            labelText={`Timesheet Effective Start Date`}
                                                            name='timesheet_start_date'
                                                            value={state.timesheet_details.timesheet_start_date}
                                                            onChange={(value) => dateChange(value, 'timesheet_start_date')}
                                                            height='54px'
                                                            error={error.timesheet_start_date}
                                                            minDate={state.billing_details.effective_from}
                                                        />
                                                        <Text red> {error.timesheet_start_date ? error.timesheet_start_date : ""}</Text>
                                                    </Grid>
                                                    {
                                                        ['Weekly', 'Bi Weekly'].includes(state.timesheet_details.timesheet_cycle) ?
                                                            <Grid item lg={6} md={4} sm={6} xs={12} pt={3}>
                                                                <Select
                                                                    name='timesheet_start_day'
                                                                    value={state.timesheet_details.timesheet_start_day}
                                                                    onChange={(e) => { changeHandler(e, 'ts') }}
                                                                    options={dayslist}
                                                                    label={'Week Start Day'}
                                                                    error={error.timesheet_start_day}
                                                                    helperText={error.timesheet_start_day ? <Text red> {error.timesheet_start_day ? error.timesheet_start_day : ""}</Text> : ""}
                                                                />
                                                            </Grid>
                                                            : null
                                                    }
                                                    <Grid item lg={12} md={12} sm={12} pt={4}>
                                                        <Text blackHeader1>Timesheet Approval Configuration
                                                            <BlackToolTip
                                                                placement="bottom"
                                                                arrow
                                                                title={
                                                                    <React.Fragment>
                                                                        <Box p={'5px'}>
                                                                            <Text smallWhite>
                                                                                If a single user at the<br />current level approves the<br />timesheet, it will progress <br />to the next level of<br />Approvers
                                                                            </Text>
                                                                        </Box>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <img src={info} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: 'pointer' }} />
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            state.timesheet_details.approval_levels.map((i, key) => (
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Box my={1}>
                                                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                                                            <div style={{ width: '100%', paddingTop: i.approval_users.length > 0 ? '16px' : '0px', minHeight: '69px', display: 'flex', alignItems: "center", }}>
                                                                                <Autocomplete
                                                                                    multiple
                                                                                    // freeSolo
                                                                                    limitTags={5}
                                                                                    id="multiple-limit-tags"
                                                                                    options={approvalDropdown}
                                                                                    getOptionLabel={(option) => option.value}
                                                                                    renderOption={(props, option) => (
                                                                                        <li {...props} key={option.uniqueIdentifier}>
                                                                                            {option.value}
                                                                                        </li>
                                                                                    )}
                                                                                    value={i.approval_users}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} label={`Level ${key + 1} Approvers (Optional)`} pt={2} />
                                                                                    )}
                                                                                    onChange={(e, newArr) => handleChangeLevels(e, newArr, key)}
                                                                                    renderTags={(value, getTagProps) =>
                                                                                        value.map((option, keyId) => (
                                                                                            <Chip
                                                                                                {...getTagProps({ keyId })}
                                                                                                key={keyId}
                                                                                                label={option && option.value}
                                                                                                sx={{ gap: '6px', m: "4px 6px", p: "4px", font: "12px Quicksand", fontWeight: 500, }}
                                                                                                onDelete={() => handleDeleteChipLevels(keyId, key)}
                                                                                                deleteIcon={<DeleteIcon />}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        "& .MuiInputBase-root": {
                                                                                            cursor: "pointer",
                                                                                        },
                                                                                        "& .MuiInputBase-input": {
                                                                                            cursor: "pointer",
                                                                                        },
                                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                                            border: "none"
                                                                                        },
                                                                                        "& .MuiAutocomplete-endAdornment": {
                                                                                            display: "none"
                                                                                        },
                                                                                        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                                                            transform: "translate(10px, 16px) scale(1);"
                                                                                        },
                                                                                        "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                            color: "#737373",
                                                                                            fontSize: "14px",
                                                                                            fontFamily: "Quicksand",
                                                                                            fontWeight: 400,
                                                                                        },
                                                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                            color: "#737373",
                                                                                            fontSize: "15px",
                                                                                            fontFamily: "Quicksand",
                                                                                            fontWeight: 500,
                                                                                        },
                                                                                        "&.Mui-focused .MuiInputLabel-outlined": {
                                                                                            color: "#737373",
                                                                                            fontSize: "10px",
                                                                                            fontFamily: "Quicksand",
                                                                                            fontWeight: 400,
                                                                                            transform: i.approval_users.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                state.timesheet_details.approval_levels.length > 1 ?
                                                                                    <Box mx={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", }}>
                                                                                        <RemoveSvg onClick={() => handleDeleteLevel(key)} style={{ cursor: "pointer" }} />
                                                                                    </Box> : null
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>

                                                            ))
                                                        }
                                                    </Grid>
                                                    {
                                                        state.timesheet_details.approval_levels.length == 0 ? ''
                                                            :
                                                            (state.timesheet_details.approval_levels.length < 5 && state.timesheet_details.approval_levels[state.timesheet_details.approval_levels.length - 1].approval_users.length > 0) &&
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Button addNewFull onClick={() => handleAddNewLevel()}>Add New Level</Button>
                                                            </Grid>
                                                    }
                                                    <Grid item container lg={12} md={12} sm={12} pt={4} alignItems={"center"}>
                                                        <Checkbox
                                                            sx={{ paddingLeft: "0px !important" }}
                                                            size='small'
                                                            name="is_timesheet_attachment_mandatory"
                                                            checked={state.timesheet_details.is_timesheet_attachment_mandatory}
                                                            value={state.timesheet_details.is_timesheet_attachment_mandatory}
                                                            onChange={(e) => { changeHandler(e, 'ts') }}
                                                            icon={<CheckBorderIcon />}
                                                            checkedIcon={<CheckedIcon />}
                                                        />
                                                        <Text mediumGreyHeader1 sx={{ font: "15px Quicksand !important", color: "#1A141F !important" }}>Make timesheet attachment mandatory</Text>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                            </SwipeableViews>
                            <Grid item container p={'10px 20px 0px 20px'}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button blackCancelBtn onClick={back}>{activeStep == 0 ? 'Cancel' : 'Back'}</Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} textAlign='end'>
                                    <LoadingButton loading={submitLoader} saveNcontinueSmall onClick={() => { handleSubmit() }}>{activeStep == 3 ? 'Finish' : 'Continue'}</LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container>
                        <Box sx={{ marginLeft: "100px" }}>
                            <Text mediumViewAmt >Add Placement</Text>
                        </Box>
                        {NoPermission()}
                    </Grid>
            }


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width='100px' height='100px' />
            </Backdrop>
            <ReusablePopup iconHide openPopup={openCancel} setOpenPopup={setOpenCancel} white fixedWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={cancelImg} alt='success' />
                    <Text popupHead1 sx={{ paddingTop: '25px !important' }}>Are you Sure?</Text>
                    <Text mediumOverView sx={{ padding: '10px 0px 30px 0px !important' }}>Do you want to cancel adding this<br /> Placement? Please be aware that any data<br /> you've entered may be lost.</Text>
                    <Box display='flex' flexDirection='row' gap={1}>
                        <Button onClick={() => setOpenCancel(false)} redBorder>No</Button>
                        <Button onClick={() => navigate('/placements/placementsInfo')} blueButton>Yes</Button>
                    </Box>
                </Box>
            </ReusablePopup >
            <ReusablePopup iconHide openPopup={openJobTitle} setOpenPopup={setOpenJobtitle} white fixedWidth>
                <AddJobTitle open={openJobTitle} closePopup={setOpenJobtitle} jobTitleDropdown={jobTitleDropdown} />
            </ReusablePopup>
            <ReusablePopup iconHide openPopup={openrecruiter} setOpenPopup={setOpenRecruiter} white fixedWidth>
                <AddRecruiter open={openrecruiter} closePopup={setOpenRecruiter} recruiterDropdown={recruiterDropdown} />
            </ReusablePopup>
            <ReusablePopup openPopup={deleteLevelOpen} setOpenPopup={setDeleteLevelOpen} white fixedWidth iconHide>
                <Grid container sx={{ margin: "30px 0px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} >
                        <img src={approvalDelete} alt='approvalDelete' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want delete the Pay roll configuration level</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button outlineBlue sx={{ width: "122px !important", height: "44px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }} onClick={() => { setDeleteLevelOpen(false); setDeleteLevelIndex(null) }} >
                                Cancel
                            </Button>
                            <Button sx={{ width: "122px !important", height: "44px !important" }} smallBlue redBackground onClick={() => { handleRemove(deleteLevelIndex) }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openSuccess} setOpenPopup={setOpenSuccess} white fixedWidth iconHide>
                <Grid container lg={12} textAlign='center' sx={{ padding: "5px" }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} >
                        <img src={placementSuccess} alt='placementSuccess' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text largeBoldGreen>Congratulations</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} py={2}>
                        <Text mediumGrey2>You have successfully placed <br /><span style={{ color: '#0C75EB' }}>{employeeData ? employeeData.full_name : ""}</span> to {state.client_details.client_name}</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Button save onClick={() => navigate('/placements')} >
                            Go To Home
                        </Button>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openDefaultTS} setOpenPopup={setOpenDefaultTS} white fixedWidth iconHide crossIcon>
                <Grid container lg={12} textAlign='center' sx={{ padding: "5px" }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} >
                        <img src={defaultTs} alt='defaultTS' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} py={2}>
                        <Text largeLabel400>Do you want to make this timesheet<br /> configuration as default?</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={1}>
                        <Box display='flex' flexDirection='row' gap={2}>
                            <Button popupCancelHeight sx={{ height: '44px !important' }} onClick={() => { setOpenDefaultTS(false); setDataExists(false) }} >
                                No
                            </Button>
                            <LoadingButton popupSaveBlueHeight sx={{ height: '44px !important', fontWeight: '500 !important' }} onClick={() => defaultTsHandler()} >
                                Yes
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </Box >
    )
}

export default ClientAndEndClient;
