import { Backdrop, Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import Text from '../../components/customText/Text';
import logoName from '../../assets/svg/logoName.svg';
import OnboardStyles from './OnboardStyles';
import Dropzone from 'react-dropzone';
import download from '../../assets/employee/filedownload.svg';
import Button from '../../components/customButton/Button';
import { addErrorMsg, addLoader, BlackToolTip, removeLoader, scrollToTop } from '../../utils/utils';
import Loader from '../../assets/gif/japfuLoader.gif';
import onBoardLinkApiInstance from '../../apis/onBoardLink/onBoardLinkApi';
// import clear from '../../assets/employee/cross-grey-circle.svg';
import LocalStorage from '../../utils/LocalStorage';
import { domain } from '../../config/Domain';
import docReject from '../../assets/svg/docReject.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function OnboardEmployeeReUploadDocs({ state, setState, id, active, setActive, preView, setPreview, getInviteViaLink, data }) {


    let dataKeys = Object.keys(data.upload_documents)

    let x = dataKeys.filter(
        (i) =>
            data.upload_documents[i].checked &&
            Array.isArray(data.upload_documents[i].data) &&
            data.upload_documents[i].data.some(obj => obj.reason_for_rejection !== "")
    );

    let xl = dataKeys.filter((i) => {
        const document = data.upload_documents[i];

        if (!Array.isArray(document.data)) return false; // Skip if data is not an array

        return document.data.some((obj) => {
            if (i === "w4_document") {
                return obj.w4_reason_for_rejection && obj.w4_reason_for_rejection.trim() !== "";
            } else if (i === "i9_document") {
                return obj.i9_reason_for_rejection && obj.i9_reason_for_rejection.trim() !== "";
            }
            return false; // Default case if the key doesn't match
        });
    });

    let combinedKeys = Array.from(new Set([...x, ...xl]));


    let options = {
        counter_signed_offer_letter: {
            name: 'Countersigned Offer Letter',
            content: `A countersigned offer is a document that has been signed by both the employer and the prospective employee, indicating mutual agreement and acceptance of the terms outlined in the job offer. This counter offer can be downloaded from the mail.`
        },
        passport: {
            name: 'Passport',
            content: `Please include all pages with stamps or visas, along with the biodata page, ensuring correct sizing and order, excluding blank pages.`
        },
        i94: {
            name: 'I94',
            content: `Please upload your record showing entry date, immigration status, and authorized duration of stay.`
        },
        signed_ssn: {
            name: 'Signed SSN',
            content: `Your "signed SSN" on official documents like the Social Security card or tax forms is validated for various purposes like identity verification, employment eligibility, tax reporting, and accessing government benefits or services.`
        },
        work_authorization: {
            name: 'Work Authorization',
            content: `Please upload your current visa document and previous visa documents.`
        },
        all_previous_i20s: {
            name: `All Previous I-20’s`,
            content: `The "previous signed I-20" is a prior Form I-20, a crucial document issued by U.S. educational institutions to international students, confirming acceptance and visa status terms.`
        },
        education_documents: {
            name: 'Education Documents',
            content: `Upload your undergraduate and highest degree documents.`
        },
        drivers_license: {
            name: 'Driving License/State ID',
            content: `Please upload a clear copy of your Driver's License. This document serves as an official identification and verifies your driving eligibility.`
        },
        copy_of_void_cheque: {
            name: 'Copy Of Void Cheque',
            content: `A void cheque bears the word 'VOID' prominently. It's a blank cheque used to share account details securely. Please enter max 5 void cheques.`
        },
        i9_document: {
            name: 'I9',
            content: ``
        },
        w4_document: {
            name: 'W4',
            content: ``
        }
    }

    const classes = OnboardStyles();
    const [expand, setExpand] = useState(true);
    const [disableExpand, setDisableExpand] = useState(false);
    const [status, setStatus] = useState('');
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [name, setName] = useState(combinedKeys[0]);

    console.log(name);


    const handleExpand = (args, name) => {
        if (args == active) {
            setExpand(!expand)
        } else {
            if (name === 'active') {
                setDisableExpand(!disableExpand);
                setStatus(args);
            } else {
                setDisableExpand(disableExpand);
                setStatus('');
            }
        }
    }
    const handleDrop = (e) => {
        if (e.length <= 10) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'jpg', 'png', 'jpeg'].includes(r))) {
                    if (Number(((e[i].size / (1024 * 1024)).toFixed(2))) < 25) {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        // formData.append("tenant_id", '4a0bcfaf-5fb3-4d88-b133-b1b5eb6c137f');
                        onBoardLinkApiInstance.documentUploadInvite(
                            name == 'copy_of_void_cheque' ? 'bank-document' : name == 'counter_signed_offer_letter' ? 'personal-document' : name == 'work_authorization' ? 'visa-document' :
                                name == 'all_previous_i20s' ? 'visa-document' : name == 'signed_ssn' ? 'education-document' : name == 'education_documents' ? 'education-document' :
                                    name == 'passport' ? 'passport-document' : name == 'i94' ? 'i94-document' : name == 'drivers_license' ? 'personal-document' : 'bank-document', formData).then((res) => {
                                        if (res.data.statusCode == 1003) {
                                            if (name == 'counter_signed_offer_letter' && state.re_upload_documents.counter_signed_offer_letter.data.length < 5) {
                                                state.re_upload_documents.counter_signed_offer_letter.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.counter_signed_offer_letter.data);
                                            } else if (name == 'work_authorization' && state.re_upload_documents.work_authorization.data.length < 5) {
                                                state.re_upload_documents.work_authorization.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.work_authorization.data);
                                            } else if (name == 'all_previous_i20s' && state.re_upload_documents.all_previous_i20s.data.length < 5) {
                                                state.re_upload_documents.all_previous_i20s.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.all_previous_i20s.data);
                                            } else if (name == 'signed_ssn' && state.re_upload_documents.signed_ssn.data.length < 1) {
                                                state.re_upload_documents.signed_ssn.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.signed_ssn.data);
                                            } else if (name == 'education_documents' && state.re_upload_documents.education_documents.data.length < 10) {
                                                state.re_upload_documents.education_documents.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.education_documents.data)
                                            } else if (name == 'passport' && state.re_upload_documents.passport.data.length < 1) {
                                                state.re_upload_documents.passport.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.passport.data)
                                            } else if (name == 'i94' && state.re_upload_documents.i94.data.length < 1) {
                                                state.re_upload_documents.i94.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.i94.data)
                                            } else if (name == 'drivers_license' && state.re_upload_documents.drivers_license.data.length < 1) {
                                                state.re_upload_documents.drivers_license.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.drivers_license.data);
                                                // const data1 = {
                                                //     id: res.data.data.id,
                                                //     url: res.data.data.document_url
                                                // }
                                                // EmployeesApi.ocrDocumentUpload('dl-data', data1).then((res) => {
                                                //     if (res.data.statusCode == 1003) {
                                                //         if (i === e.length - 1) {
                                                //             setOpenBackDrop(false);
                                                //         }
                                                //         setPreview(state.re_upload_documents.drivers_license.data);
                                                //     } else {
                                                //         addErrorMsg(res.data.message);
                                                //     }
                                                // })
                                            }
                                            else if (name == 'copy_of_void_cheque' && state.re_upload_documents.copy_of_void_cheque.data.length < 5) {
                                                state.re_upload_documents.copy_of_void_cheque.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                setPreview(state.re_upload_documents.copy_of_void_cheque.data);
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                            } else if (name == 'i9_document' && state.re_upload_documents.i9_document.data.length < 1) {
                                                // console.log(state);

                                                state.re_upload_documents.signed_ssn.data.push({
                                                    id: res.data.data.id,
                                                    document_url: res.data.data.document_url,
                                                    document_name: res.data.data.document_name,
                                                    approval_status: "Submitted"
                                                });
                                                if (i === e.length - 1) {
                                                    setOpenBackDrop(false);
                                                }
                                                setPreview(state.re_upload_documents.signed_ssn.data);

                                            }
                                            else {
                                                setOpenBackDrop(false);
                                                if (name == 'i9_document' || name == 'w4_document') {
                                                    addErrorMsg(`You can upload a maximum of 1 files at a time. Please remove excess files and try again`)
                                                } else {
                                                    addErrorMsg(`You can upload a maximum of ${active == 3 || active == 2 || active == 4 || active == 8 ? '1' : active == 7 ? '10' : '5'} files at a time. Please remove excess files and try again`)
                                                }

                                            }
                                            setState({ ...state })
                                        } else {
                                            setOpenBackDrop(false);
                                            addErrorMsg(res.data.message);
                                        }
                                    })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or Image format only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
        }
    }

    const deleteChip = (args) => {
        if (name == 'copy_of_void_cheque') {
            state.re_upload_documents.copy_of_void_cheque.data.splice(args, 1)
        } else if (name == 'counter_signed_offer_letter') {
            state.re_upload_documents.counter_signed_offer_letter.data.splice(args, 1)
        } else if (name == 'work_authorization') {
            state.re_upload_documents.work_authorization.data.splice(args, 1)
        } else if (name == 'all_previous_i20s') {
            state.re_upload_documents.all_previous_i20s.data.splice(args, 1)
        } else if (name == 'signed_ssn') {
            state.re_upload_documents.signed_ssn.data.splice(args, 1)
        } else if (name == 'education_documents') {
            state.re_upload_documents.education_documents.data.splice(args, 1);
        } else if (name == 'passport') {
            state.re_upload_documents.passport.data.splice(args, 1);
        } else if (name == 'i94') {
            state.re_upload_documents.i94.data.splice(args, 1);
        } else if (name == 'drivers_license') {
            state.re_upload_documents.drivers_license.data.splice(args, 1);
        }
        setState({ ...state });
    }


    const updateApi = () => {
        state['subdomain_name'] = domain
        state['request_id'] = LocalStorage.uid();
        if (active == combinedKeys.length) {
            state['submit'] = true;
            Object.keys(state.re_upload_documents).forEach(key => {
                if (state.upload_documents.hasOwnProperty(key)) {
                    state.upload_documents[key] = state.re_upload_documents[key];
                }
            });
        }
        addLoader(true);
        onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                getInviteViaLink(id);
                if (active <= (combinedKeys.length - 1)) {
                    setActive(active + 1);
                    setName(combinedKeys[active]);
                } else if (active == combinedKeys.length) {
                    scrollToTop();
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleSubmit = () => {
        addLoader(true);
        updateApi();
    }
    const handleSkip = () => {
        if (active <= (combinedKeys.length - 1)) {
            setActive(active + 1);
            setName(combinedKeys[active]);
        } else if (active == combinedKeys.length) {
            scrollToTop();
        }
    }
    const handleBack = () => {
        if (active <= combinedKeys.length) {
            setActive(active - 1);
            setName(combinedKeys[active - 2]);
        }
    }
    return (
        <Grid container>
            <Grid item lg={2} md={2} sm={2} xs={2}>
                <img src={data ? data.logo_url : logoName} alt="logoName" className={classes.logoStyles}></img>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                <Grid item xl={8} lg={9} md={11} sm={11} xs={12} pt={3}>
                    <Text BrowmnMnStepperText>Uploaded Documents</Text>
                </Grid>
                <Grid item container xl={8} lg={9} md={11} sm={11} xs={12} justifyContent='center' textAlign='center' pt={7} spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ height: '550px', overflowY: 'scroll' }}>
                        {
                            combinedKeys.map((item, index) => (
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: "fit-content", textAlign: "left" }} className={active == index + 1 ? classes.blueBg : classes.greyBg} onClick={() => handleExpand(index + 1, 'active', item)}>
                                    {
                                        active == index + 1 ?
                                            <>
                                                <Text largeWhite>{index + 1}. {options[item].name}</Text>
                                                {
                                                    (active == index + 1) && expand ?
                                                        <Text mediumBoldWhite400 sx={{ paddingTop: '10px', paddingLeft: "10px" }}>{options[item].content}</Text> : ''
                                                }
                                            </> :
                                            <>
                                                <Text overViewLable>{index + 1}. {options[item].name}</Text>
                                                {
                                                    disableExpand && (status == (index + 1)) ?
                                                        <Text smallGreyFont sx={{ paddingTop: '10px', paddingLeft: "10px" }}>{options[item].content}</Text> : ''
                                                }
                                            </>
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>

                    <Grid item container spacing={2} lg={8} md={8} sm={8} xs={12}>
                        {state?.re_upload_documents[name]?.data?.length > 0 ? '' :
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ width: "100%", height: "fit-content", border: "1px solid #EAECF0", padding: "10px", textAlign: "start", borderRadius: "8px" }}>
                                    <Box display={"flex"} alignItems={"center"} gap="5px" width={"100%"}>
                                        <img src={docReject} alt="docReject" />
                                        <Box width={"100%"}>
                                            <Text largeBlack700>{state?.upload_documents[name]?.name}</Text>
                                            <Text black12><span style={{ font: '13px Quicksand', fontWeight: 700 }}>Reason For Rejection:</span>
                                                {state?.upload_documents[name]?.data[0]?.reason_for_rejection}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        }

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className={classes.dropZone}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Box className={classes.dropzoneContainer}>
                                                <Grid container spacing={1} textAlign='center' justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} marginTop={'-8px'}>
                                                        <img src={download} alt='download' />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Text largeBlack>Drag & Drop Documents</Text>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Text smallGrey>or</Text>
                                                    </Grid>
                                                    <Box display='flex' flexDirection='row' gap={2} textAlign='center' p={'10px 0px 20px 0px'}>
                                                        <Text smallBlue1 className={classes.clickhere}>Click Here</Text>
                                                        <Text largeBlack>To Upload</Text>
                                                    </Box>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                        <Text smallGreyFont>Document Size 25 MB, Format - PDF,  IMG (Jpeg, PNG)</Text>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                            {
                                state?.re_upload_documents[name]?.data?.length > 0 ?
                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#0C75EB !important", textAlign: "left !important" }}>Uploaded Documents</Text>
                                        <Grid item container lg={12} md={12} columnGap={1.5}>
                                            {
                                                state?.re_upload_documents[name]?.data.map((item, index) => (
                                                    <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                        <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important` }}>
                                                            <a href={item.document_url} style={{ color: "#0C75EB" }} target="_blank" rel="noreferrer">
                                                                {item.document_name.length > 24 ?
                                                                    <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 24)} {item.document_name.length > 24 ? '...' : ''}
                                                                    </BlackToolTip>
                                                                    :
                                                                    <>
                                                                        {item.document_name}
                                                                    </>}
                                                            </a>
                                                        </Text>
                                                        <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { deleteChip(index) }} />
                                                    </Box>
                                                ))
                                            }
                                        </Grid>
                                    </Box> :
                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "15px 10px", background: "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "14px Quicksand !important", color: "#737373 !important", textAlign: "left !important" }}>Uploaded Documents</Text>
                                        <Grid item container lg={12} columnGap={1.5}>
                                        </Grid>
                                    </Box>
                            }
                        </Grid>
                        <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item lg={7} md={5} sm={5} xs={12} textAlign='start'>
                                {
                                    active == 1 ? '' :
                                        <Button blackCancel onClick={handleBack}>Back</Button>
                                }
                            </Grid>
                            <Grid item lg={5} md={7} sm={7} xs={12} textAlign='end'>
                                {active == combinedKeys.length ?
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                        <Button saveNcontinueSmall onClick={handleSubmit}>Submit</Button>
                                    </Box> :
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='end'>
                                        <Button borderlessSkip onClick={handleSkip}>Skip</Button>
                                        <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Grid >
    )
}

export default OnboardEmployeeReUploadDocs