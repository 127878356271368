import React, { useState, useRef, useEffect } from 'react'
import { Box, ButtonGroup, Grid, Skeleton, Slide } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import ToggleSwitch from '../../../../../components/toggle/CustomToggle'
import CustomMenu from '../../configComponents/customMenu/Menu'
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg';
import EditIcon from '../../../../../assets/svg/newEdit.svg';
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../../components/input/Input';
import { isValid, onCharactersAndNumbersWithSpaceChange, validate_emptyField, } from "../../../../../components/Validation";
import EmploymentCategoryApi from '../../../../../apis/configurations/employee/EmploymentCategoryApi';
import { BlackToolTip, ListLoadingIcon, NoDataFound, NoPermission, addErrorMsg, addWarningMsg, capitalize } from '../../../../../utils/utils';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg } from '../../../../../utils/utils';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import Select from '../../../../../components/select/Select';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import ReusableAlert from '../../../../../components/reuablePopup/ReusableAlert';
import { ErrorMessages } from '../../../../../utils/ErrorMessages';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper ": {
    width: '692px',
    padding: '0px !important',
    borderRadius: "12px",
  },
  "& .MuiDialogContent-root": {
    padding: '0px !important',
  },
  "& .MuiDialogActions-root": {
    padding: '0px !important'
  },
  "& .MuiDialog-container": {
    background: 'rgba(0, 0, 0, 0.55) !important'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={300} />;
});

function EmploymentCategory({ current }) {

  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

  const classes = MainStyles()
  const recentSection = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState({});
  const [getData, setGetData] = useState([]);
  const [drop, setDrop] = useState([]);
  const [loading, setLoading] = useState(true);// eslint-disable-next-line 
  const [activityData, setActivityData] = useState([]);// eslint-disable-next-line 
  const statusList = require('../../../../../utils/jsons/Status.json');
  const innerBoxRef = useRef(null);
  const activityRef = useRef(null);
  const [currentTab, setCurrentTab] = useState('Contractor');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [state, setState] = useState({
    serial_no: '',
    id: '',
    employment_type_name: '',
    employment_type_id: '',
    name: '',//category name
    created_by: '',
    updated_by: '',
    is_active: true,
    description: '',
    is_editable: '',
  });
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    employment_type_id: 3,
    search: ""
  });
  const [activityFilter, setActivityFilter] = useState({
    limit: 10,
    page: 1,
  })
  const [total, setTotal] = useState()
  const [activityTotal, setActivityTotal] = useState();
  const [inactiveOpen, setInactiveOpen] = useState(false);
  const [inActiveData, setInactiveData] = useState(null);
  const [recentActivityShow, setrecentActivityShow] = useState(false)
  const [listLoading, setListLoading] = useState(false);
  const [chromeAlert, setChromeAlert] = useState(false);
  useEffect((id) => {
    if (!LocalStorage.getStartTour()?.orgconfig) {
      if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true))) {
        setTimeout(() => {
          getCategory(filter)
          getEmployeeDropdown()
          getActivity(activityFilter)
        }, 300)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event) => {

      if (chromeAlert) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chromeAlert]);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (e.target.name == 'name') {
      setState({ ...state, [e.target.name]: capitalize(e.target.value) })
    } else {
      setState({ ...state, [e.target.name]: e.target.value })
    }
    handleValidations(e);
  };
  const handleStatus = (event, items) => {
    if (event.target.checked) {
      items['is_active'] = event.target.checked
      updateStatus(items)
    } else {
      items['is_active'] = event.target.checked;
      setInactiveData(items);
      setInactiveOpen(true);
    }
    // state['is_active'] = event.target.checked
    // setState(state);
    // updateStatus(items)
  }
  const handleValidations = (e) => {
    let input = e.target;
    let err = error;
    switch (input.name || input.tagName) {
      case "name":
        err.name = validate_emptyField(input.value);
        break;
      case "employment_type_id":
        err.employment_type_id = validate_emptyField(input.value);
        break;
      // case "is_active":
      //   err.is_active = validate_emptyField(input.value);
      //   break;
      default:
        break;
    }
    setError(err);
  }

  const validateAll = () => {
    let { name, employment_type_id } = state;
    let errors = {};
    errors.name = validate_emptyField(name);
    errors.employment_type_id = validate_emptyField(employment_type_id);
    // errors.description = validate_emptyField(description);
    // errors.is_active = validate_emptyField(is_active);
    return errors;

  };

  const handleSubmit = () => {
    let errors = validateAll();
    if (isValid(errors)) {
      if (state.id != "") {
        updateCategory(state.id)
      } else {
        storeEmployeeCategory(state)
      }
      // handleDialogClose();
    } else {
      setError(errors);
      addWarningMsg(ErrorMessages.genericMessage);
      setLoading(false);
    }
  }


  const handleClickOpen = () => {
    setOpen(true);
    setError({});
    setState({
      serial_no: '',
      id: '',
      employment_type_name: '',
      employment_type_id: filter.employment_type_id == 3 ? 3 : 2,
      name: '',
      created_by: '',
      updated_by: '',
      is_active: true,
      description: '',
      is_editable: '',
    });
    setChromeAlert(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    if (isEditable) {
      setIsEditable(isEditable)
    }
    setIsEditable(false);
    setError({});
    setChromeAlert(false);
    // setOpen(false);

  };

  const handleEditClick = (data) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true)))) {
      setOpen(true);
      setAnchorEl(null);
      setIsEditable(false);
      setState({ ...data });
      setChromeAlert(true);
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }
  };

  const handleViewClick = (data) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true)))) {
      setOpen(true);
      setAnchorEl(null);
      setIsEditable(true);
      setState({ ...data });
    } else {
      addWarningMsg("You don't have permission to perform this action. Please contact the admin")
    }

  }

  const handleSaveAndEdit = () => {
    if (isEditable) {
      setIsEditable(false);
    } else {
      // if (state.id != "") {
      //   updateCategory(state.id)
      // } else {
      //   storeEmployeeCategory(state)
      handleSubmit()
      // }
    }
  }

  const handleDeleteClick = (args) => {
    if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_delete" && item.is_allowed == true)))) {
      setDeleteData(args.id);
      setDeleteOpen(true);
    } else {
      addWarningMsg("You don't have permission to perform the action. Please contact the admin")
    }
  }

  const scrollDown = () => {
    window.scroll({
      top: recentSection.current.scrollIntoView(),
      behavior: 'smooth'
    })
    setrecentActivityShow(false)
  }

  const getCategory = (args) => {
    // if (filter.limit <= 10) {
    setLoading(true)
    // } else {
    //   setLoading(false)
    // }
    EmploymentCategoryApi.getEmploymentCategories(args).then((response) => {
      setTimeout(() => {
        if (response.data.statusCode == 1003) {
          setLoading(false)
          setGetData(response.data.data);
          setTotal(response.data.pagination.total);
        } else {
          setLoading(false);
          addErrorMsg(response.data.message);
        }
      }, 300);
      // removeLoader();
      setListLoading(false);
    });
  };

  const getActivity = (args, popup) => {
    // setLoading(true)
    let id = 1;
    ConfigApi.getActivity(id, "", args).then((response) => {
      setTimeout(() => {
        if (response.data.statusCode == 1003) {
          // setLoading(false)
          setActivityData(response.data.data);
          setActivityTotal(response.data.pagination.total)
          if (popup) { }
          else {
            setrecentActivityShow(true)
          }
        }
      }, 300)
      // removeLoader();
      setListLoading(false);
    });
  };

  const updateCategory = (dataId) => {
    let data = { ...state, request_id: LocalStorage.uid() };

    setLoading(true)
    EmploymentCategoryApi.updateEmployeeCategory(data, dataId).then((response) => {
      setTimeout(() => {
        setLoading(false)
        if (response.data.statusCode == 1003) {
          getCategory(filter)
          handleDialogClose();
          addSuccessMsg(response.data.message);
          getActivity(activityFilter)
        } else {
          addErrorMsg(response.data.message);
          //handleDialogClose();
        }
      }, 600)

    });
  };

  const updateStatus = (args) => {
    let data = {
      request_id: LocalStorage.uid(),
      is_active: args.is_active
    }
    EmploymentCategoryApi.updateEmployeeStatus(data, args.id).then((response) => {
      if (response.data.statusCode == 1003) {
        addSuccessMsg('Status Updated Successfully');
        setInactiveData(null);
        setInactiveOpen(false)
        getCategory(filter)
        getActivity(activityFilter)
      } else {
        setInactiveOpen(false);
        getCategory(filter)
        getActivity(activityFilter)
        addErrorMsg(response.data.message);
      }
    });
  };

  const handleCloseInActive = () => {
    setInactiveData(null);
    setInactiveOpen(false);
    getCategory(filter)
  }

  const deleteCategory = (dataId) => {
    let data = { request_id: LocalStorage.uid() };
    EmploymentCategoryApi.deleteEmployeeCategory(data, dataId).then((response) => {
      if (response.data.statusCode == 1003) {
        setDeleteOpen(false);
        getCategory(filter)
        getActivity(activityFilter)
        addSuccessMsg(response.data.message);
      } else {
        setDeleteOpen(false);
        addErrorMsg(response.data.message);
      }
    });
  };

  const storeEmployeeCategory = (data) => {
    data.request_id = LocalStorage.uid()
    setLoading(true)
    EmploymentCategoryApi.postEmployeeCategory(data).then((response) => {
      setTimeout(() => {
        setLoading(false)
        if (response.data.statusCode == 1003) {
          getCategory(filter)
          handleDialogClose();
          getActivity(activityFilter)
          addSuccessMsg(response.data.message);
        }
        else {

          addErrorMsg(response.data.message);
        }
      }, 600)

    });
  };

  const getEmployeeDropdown = () => {
    // setLoading(true)
    EmploymentCategoryApi.getEmploymentTypeDropDown().then((response) => {
      if (response.data.statusCode == 1003) {
        setDrop(response.data.data)
      }
    });
  };

  // const getIndividualIndex = (id) => {
  //   EmploymentCategoryApi.getEmploymentIndex(
  //     id
  //   ).then((response) => {
  //     setTimeout(() => {
  //       // setLoading(false)
  //       if (response.data.statusCode == 1003) {
  //         setState(response.data.data)
  //         console.log(response.data.data, " 66666666666666");
  //         // setIndexData(response.data.data);
  //       } else {
  //         addErrorMsg(response.data.message);
  //       }
  //     }, 400)
  //   });
  // }
  // window.addEventListener('auto', () => {
  //   console.log('????');
  // });


  const handleScroll = () => {
    const { current } = innerBoxRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight) {
        // getCategory({...filter})
        if (total >= filter.limit) {
          // addLoader(true);
          setListLoading(true);
          setFilter({ ...filter, limit: filter.limit + 10, page: 1 })
          getCategory({ ...filter, limit: filter.limit + 10, page: 1, });
        }


        // Reached the bottom of the inner box
        // console.log('Reached end of inner box, calling a function...');
        // Call your function here
      }
    }
  };
  const activityHandleScroll = () => {
    const { current } = activityRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        // getCategory({...filter})
        if (Number(activityTotal) >= activityFilter.limit) {
          loadMoreData();
          // setActivityFilter({ ...activityFilter, limit: activityFilter.limit + 10, page: 1 })
          // getActivity({ ...activityFilter, limit: activityFilter.limit + 10, page: 1, });
        }
        // Reached the bottom of the inner box
        // console.log('Reached end of inner box, calling a function...');
      } else {
        // console.log("complete else");
      }
    }
  };

  const loadMoreData = () => {
    // addLoader(true);
    setListLoading(true);
    activityFilter.limit = Number(activityFilter.limit) + 10;
    setActivityFilter({ ...activityFilter });
    getActivity(activityFilter, true);
  }

  const handleChangeTab = (param) => {
    filter.limit = 10;
    filter.page = 1;
    filter.employment_type_id = param == "Contractor" ? 3 : 2;
    setFilter({ ...filter });
    getCategory(filter);
    setCurrentTab(param);
  }


  const HandleScrolling = (e) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop >= 160) {
      setrecentActivityShow(false);
    }
  };

  return (
    <div>
      {LocalStorage.getStartTour()?.orgconfig ? NoDataFound() :
        (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true))) ?
          <Box onScroll={HandleScrolling} sx={{
            height: '70vh', overflow: 'auto', padding: '16px',
            "@media (min-width: 100px) and (max-width: 499px)": {
              padding: "10px 3px !important",
            },
          }}>
            <Box className={classes.activeItemBox}>
              <Box className={classes.activeBoxHeading}>
                <Box>
                  <ButtonGroup
                    variant="outlined"
                    fullWidth
                    aria-label="outlined button group"
                  >
                    <Button
                      sx={{
                        font: "14px Quicksand !important",
                        textTransform: 'capitalize ',
                        fontWeight: "600 !important",
                        padding: "9px 15px 9px 15px",
                        borderColor: `${currentTab === "Contractor" ? "#0C75EB !important" : "#E2E5E6 !important"} `,
                        background: `${currentTab === "Contractor" ? "#0C75EB !important" : "#E2E5E6 !important"} `,
                        color: `${currentTab === "Contractor" ? "#ffffff !important" : "#849199 !important"} `,
                        // "&:hover": { borderColor: `${currentTab === "Contractor" ? "#0C75EB" : "#E2E5E6"} `, },
                        cursor: `${loading ? 'default' : 'pointer'}`,
                        "@media (min-width: 900px) and (max-width: 1400px)": {
                          fontSize: "12px !important",
                          fontWeight: "600 !important",
                        }
                      }}
                      variant={`${currentTab === "Contractor" ? "contained" : "outlined"}`}
                      onClick={() => loading ? "" : handleChangeTab("Contractor")}
                    >
                      Contractor
                    </Button>
                    <Button
                      sx={{
                        font: "14px Quicksand !important",
                        textTransform: 'capitalize ',
                        fontWeight: "600 !important",
                        padding: "9px 15px 9px 15px",
                        borderLeft: `${currentTab === "Consultant" ? 'none' : ''}`,
                        background: `${currentTab === "Consultant" ? "#0C75EB !important" : "#E2E5E6 !important"} `,
                        borderColor: `${currentTab === "Consultant" ? "#0C75EB !important" : "#E2E5E6 !important"} `,
                        color: `${currentTab === "Consultant" ? "#ffffff !important" : "#849199 !important"}`,
                        cursor: `${loading ? 'default' : 'pointer'}`,
                        "&:hover": {
                          borderColor: `${currentTab === "Consultant" ? "#0C75EB" : "#E2E5E6"}`,
                          borderLeft: "none",
                          background: "none"
                        },
                        "@media (min-width: 900px) and (max-width: 1400px)": {
                          fontSize: "12px !important",
                          fontWeight: "600 !important"
                        }
                      }}
                      variant={`${currentTab === "Consultant" ? "contained" : "outlined"}`}
                      onClick={() => loading ? "" : handleChangeTab("Consultant")}
                    >
                      Consultant
                    </Button>

                  </ButtonGroup>
                </Box>

                <Grid item my={3} lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                  <Text profileTitle>{current}</Text>
                  <Text greysmallLabel pt={1}>*You can add max 5 new categories</Text>
                </Grid>
              </Box>

              <Box sx={{ height: '35vh', overflow: 'auto' }} ref={innerBoxRef} onScroll={handleScroll}>
                {loading ? <>
                  {[1, 2, 3].map((item, index) => (
                    <Grid spacing={2} container key={index} mt={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </Grid>
                      <Grid item lg={3} md={3} sm={4} xs={12}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </Grid>
                      <Grid item lg={2} md={2} sm={4} xs={12}>
                        <Skeleton animation="wave" height={40} />
                      </Grid>
                      <Grid item lg={1} md={2} sm={1} xs={12}>
                        <Skeleton animation="wave" />
                      </Grid>
                    </Grid>
                  ))}
                </>
                  :
                  <>
                    {getData.map((items) => (
                      <Box className={classes.descriptionBoxStyle} key={items.id}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item lg={4} md={4} sm={6} xs={12} pr={1}>
                            <Text mediumBlackColor >
                              <BlackToolTip title={items.name}>
                                {items.name ? items.name.length > 16 ? items.name.slice(0, 16) + '...' : items.name : "--"}
                              </BlackToolTip>
                            </Text>
                            <Text greyLabel mt={0.5}>
                              <BlackToolTip title={items.description}>
                                {items.description ? items.description.length > 20 ? items.description.slice(0, 20) + '...' : items.description : "--"}
                              </BlackToolTip>
                            </Text>
                          </Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                            <Text mediumBlackColor > Employee Type </Text>
                            <Text greyLabel mt={0.5}>{items.employment_type_name}</Text>
                          </Grid>
                          <Grid item lg={3} md={3} sm={4} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 2 }}>
                            <Text mediumBlackColor>Created By</Text>
                            <Text greyLabel mt={0.5}>
                              {
                                items.created_by && items.created_by.length > 16 ?
                                  <BlackToolTip title={items.created_by} placement="top" arrow>
                                    {items.created_by.slice(0, 16) + (items.created_by.length > 16 ? "..." : "")}
                                  </BlackToolTip>
                                  : items.created_by ? items.created_by : '--'
                              }
                            </Text>
                          </Grid>
                          <Grid item lg={1} md={1} sm={4} xs={12} mt={{ lg: 0, md: 0, sm: 0, xs: 1 }}>
                            <ToggleSwitch name='is_active' isActive={items.is_active} switchChange={(e) => handleStatus(e, items)} sx={{ height: '24px !important' }} />
                          </Grid>
                          <Grid item lg={1} md={1} sm={4} xs={12} textAlign={'center'}>
                            <CustomMenu
                              Icon={<MenuIcon />}
                              handleMenuClick={handleMenuClick}
                              anchorEl={anchorEl}
                              isOpen={Boolean(anchorEl)}
                              onClose={handleClose}
                              children=
                              {
                                (((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true))))
                                  &&
                                  ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true))))
                                  &&
                                  ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_delete" && item.is_allowed == true)))))
                                  ?
                                  [{ color: '#171717', label: "View", Click: () => handleViewClick(items) },
                                  ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true)))) && { color: '#171717', label: "Edit", Click: () => handleEditClick(items) },
                                  ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_delete" && item.is_allowed == true))))
                                  && { color: '#171717', label: "Delete", Click: () => handleDeleteClick(items) },
                                  ]
                                  :
                                  (((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true))))
                                    &&
                                    ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true)))))
                                    ?
                                    [{ color: '#171717', label: "View", Click: () => handleViewClick(items) },
                                    ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true)))) && { color: '#171717', label: "Edit", Click: () => handleEditClick(items) }
                                    ]
                                    :
                                    ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_view" && item.is_allowed == true))))
                                      &&
                                      ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_delete" && item.is_allowed == true))))
                                      ?
                                      [{ color: '#171717', label: "View", Click: () => handleViewClick(items) },
                                      ((LocalStorage.getUserData().super_admin) || ((rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_delete" && item.is_allowed == true))))
                                      && { color: '#171717', label: "Delete", Click: () => handleDeleteClick(items) },
                                      ]
                                      :
                                      [{ color: '#171717', label: "View", Click: () => handleViewClick(items) }]
                              }
                            // {
                            //   items.is_editable ?
                            //     [{ color: '#171717', label: "View", Click: () => handleViewClick(items) },
                            //     { color: '#171717', label: "Edit", Click: () => handleEditClick(items) },
                            //     { color: '#171717', label: "Delete", Click: () => handleDeleteClick(items) },
                            //     ]
                            //     :
                            //     [{ color: '#171717', label: "View", Click: () => handleViewClick(items) }]
                            // }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </>
                }
                {listLoading && (
                  <Box >
                    <ListLoadingIcon />
                  </Box>
                )}
              </Box>
              {getData.length >= 0 && getData.length < 7 ?
                <>
                  {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_create" && item.is_allowed == true))) ?
                      <Button addTitleBtn sx={{ width: '100%' }} onClick={() => handleClickOpen()}>Add Employment Category</Button> :
                      <Button addTitleBtnDisable>Add Employment Category</Button>
                  }
                </> : ''
              }

              <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
              >
                <DialogContent >
                  {
                    isEditable ?
                      <Box width={"100%"} padding={{ lg: '30px', md: "30px", sm: "25px", xs: "10px" }}>
                        <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                          <Grid item lg={6} md={6} sm={6} xs={8.5}>
                            <Text mediumViewAmt>Employment Category</Text>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={3.5} textAlign={"end"}>
                            {state.is_editable && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_category_edit" && item.is_allowed == true))) ? <img onClick={() => { setIsEditable(false); setChromeAlert(true) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                            <Text mediumGreyHeader1>Employment Type</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.employment_type_name ? state.employment_type_name : "--"}</Text>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                            <Text mediumGreyHeader1>Employment Category</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.name ? state.name : "--"}</Text>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                            <Text mediumGreyHeader1>Status</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.is_active ? "Active" : "In Active"}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Text mediumGreyHeader1>Description (Optional)</Text>
                            <Text mediumBlack14 pt={0.8} className={classes.descriptionText}>{state.description ? state.description : "--"}</Text>
                          </Grid>
                        </Grid>
                      </Box>
                      :
                      <Box padding={{ lg: '38px 30px 35px 30px', md: '38px 30px 35px 30px', sm: '30px', xs: '16px 12px' }}>
                        <Box mb={4}>
                          <Text mediumBoldBlack2>Employment Category</Text>
                        </Box>
                        <Grid container spacing={3} columnSpacing={'32px'}>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Select
                              name='employment_type_id'
                              value={state.employment_type_id}
                              commonSelect
                              label={<Text largeLabel>Employment Type</Text>}
                              options={drop}
                              disabled={true}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={12} >
                            <Input
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                name: 'name',
                                value: state.name,
                                type: 'text',
                                inputProps: { maxLength: 33 }
                              }}
                              handleChange={handleChange}
                              onKeyPress={onCharactersAndNumbersWithSpaceChange}
                              clientInput
                              labelText={'Category'}
                              error={error.name}
                            />
                            <Text errorText> {error.name ? error.name : ""}</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                              formControlProps={{
                                fullWidth: true
                              }}
                              multiline={true}
                              rows={3}
                              inputProps={{
                                name: 'description',
                                value: state.description,
                                type: 'text',
                                inputProps: { maxLength: 100 }
                              }}
                              handleChange={handleChange}
                              descriptionFormControl1
                              descriptionInput
                              labelText={'Description (Optional)'}
                              placeholder={'Type Something'}
                            />
                          </Grid>
                        </Grid>
                        <Box display={'flex'} justifyContent={'end'} gap={'20px'} pt={3}>
                          <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={() => handleDialogClose()} >Cancel</Button>
                          <LoadingButton saveBtn loading={loading} onClick={() => handleSaveAndEdit()}>Save</LoadingButton>
                        </Box>
                      </Box>
                  }
                </DialogContent>
              </BootstrapDialog>
            </Box>
            <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
              <Box mb={3}>
                <Text RegularBlack1>Recent Activities</Text>
              </Box>
              <Box sx={{ height: '40vh', overflowY: 'auto', }}
                ref={activityRef}
                onScroll={activityHandleScroll}>
                {loading ?
                  <>
                    {[1, 2, 3].map((item, index) => (
                      <Grid container key={index} mt={3}>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Skeleton animation="wave" width="200px" />
                          <Skeleton animation="wave" width="200px" />
                        </Grid>
                      </Grid>
                    ))}
                  </> :
                  <>
                    {activityData.length > 0 ?
                      <>
                        {activityData.map((value) => (
                          <Box className={classes.descriptionBoxStyle} mb={2}>
                            <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }}>
                              <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                <Text mediumBlackColor>
                                  {
                                    value.user_name && value.user_name.length > 16 ?
                                      <BlackToolTip title={value.user_name} placement="top" arrow>
                                        {value.user_name.slice(0, 16) + (value.user_name.length > 16 ? "..." : "")}
                                      </BlackToolTip>
                                      : value.user_name
                                  } {value.action}
                                  <br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                              </Grid>
                              <Grid container item lg={8} md={8} sm={6} xs={12} alignItems={"center"}>
                                {
                                  value.change_log.length > 0 && value.change_log.map((item) => (
                                    <Text mediumGrey2 className={classes.descriptionText}>{item}.</Text>
                                  ))
                                }
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </> :
                      <>
                        {NoDataFound("", "No Data Found", "50%", "35")}
                      </>
                    }
                  </>
                }
              </Box>
            </Box>
            {deleteOpen && <ReusableDelete newDelete={true} content={"Do you want to delete this employment category?"} open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteCategory(deleteData) }} />}
            {
              inactiveOpen && <ReusableAlert message={true} title='employment category' open={inactiveOpen} handleClose={handleCloseInActive} onClick={() => { updateStatus(inActiveData) }} />
            }
            {recentActivityShow ?
              <Grid container spacing={{ lg: 6, md: 4, sm: 3, xs: 1 }} justifyContent={'center'}>
                <Button addButton
                  // scrollBtn
                  sx={{ position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`, }}
                  onClick={scrollDown}
                  endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
              </Grid>
              : ''}
          </Box>
          :
          <>
            {NoPermission()}
          </>
      }
    </div>

  )
}

export default EmploymentCategory;
